import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React, { SyntheticEvent, useEffect, useState } from "react";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ClientInfo } from "../../../../types/formTypes";
import { addClient } from "../../../../store/thunks/systemManagementThunk";

interface CreateClientDialogProps {
  open: any;
  handleCreateClientClose: any;
}

const CreateClientDialog = (props: CreateClientDialogProps) => {
  const dispatch = useAppDispatch();
  const { open, handleCreateClientClose } = props;

  const { allPrograms, addUserLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );

  const handleCloseCreateClientDialog = (message: string, reason?: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setAddClientError(false);
    setAddClientErrorMessage("");
    setProgramErrorCreateClient(false);
    setProgramErrorMessageCreateClient("");
    handleCreateClientClose(message);
  };

  let createClientInitialValues: ClientInfo = {
    client_id: "",
    client_name: "",
    programs: undefined,
  };

  const [addClientError, setAddClientError] = useState(false);
  const [addClientErrorMessage, setAddClientErrorMessage] = useState("");

  const [programErrorCreateClient, setProgramErrorCreateClient] =
    useState(false);
  const [programErrorMessageCreateClient, setProgramErrorMessageCreateClient] =
    useState("");

  const validateAddClient = Yup.object({
    client_id: Yup.number().required("First Name is Required"),
    client_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Last Name is Required"),
    programs: Yup.mixed(),
  });

  const addClientForm = useFormik({
    initialValues: createClientInitialValues,
    validationSchema: validateAddClient,
    enableReinitialize: true,
    onSubmit: (values: ClientInfo) => {
      setProgramErrorCreateClient(false);
      setProgramErrorMessageCreateClient("");
      if (values.programs) {
        setProgramErrorCreateClient(false);
        setProgramErrorMessageCreateClient("");
      } else {
        setProgramErrorCreateClient(true);
        setProgramErrorMessageCreateClient("programs is required");
        return;
      }
      const createClientPayload = {
        client_id: values.client_id,
        client_name: values.client_name,
        programs: values.programs.map((program: any) => program.program_index),
      };

      dispatch(addClient({ createClientPayload })).then((result: any) => {
        if (result.payload.result === true) {
          handleCreateClientClose(result.payload.message);
        } else {
          setAddClientError(true);
          setAddClientErrorMessage(result.payload.message);
        }
      });
    },
  });

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={(event, reason) => handleCloseCreateClientDialog("", reason)}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgba(59, 147, 74, 1)",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
          {addClientForm ? "Edit Client" : "Add Client"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleCloseCreateClientDialog("")}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Spinner loading={addUserLoading} />
      <form onSubmit={addClientForm.handleSubmit}>
        <DialogContent>
          <DialogContentText>
            <Grid container item xs={12} sx={{ padding: "20px" }}>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{}}
                  placeholder="Client ID"
                  inputProps={{ type: "number" }}
                  label="Client ID"
                  id="client_id"
                  name="client_id"
                  variant="outlined"
                  fullWidth
                  value={addClientForm.values.client_id}
                  error={
                    addClientForm.touched.client_id &&
                    Boolean(addClientForm.errors.client_id)
                  }
                  helperText={addClientForm.errors.client_id}
                  onChange={addClientForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {},
                  }}
                  placeholder="Client Name"
                  label="Client Name"
                  id="client_name"
                  name="client_name"
                  variant="outlined"
                  inputProps={{ style: {} }}
                  fullWidth
                  InputLabelProps={{
                    style: {},
                  }}
                  value={addClientForm.values.client_name}
                  error={
                    addClientForm.touched.client_name &&
                    Boolean(addClientForm.errors.client_name)
                  }
                  helperText={addClientForm.errors.client_name}
                  onChange={addClientForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <Autocomplete
                  id="programs"
                  multiple
                  value={addClientForm.values.programs}
                  onChange={(_: SyntheticEvent, newValue: any | null) => {
                    if (newValue) {
                      addClientForm.setFieldValue("programs", newValue);
                    } else {
                      addClientForm.setFieldValue("programs", undefined);
                    }
                  }}
                  options={allPrograms ? allPrograms : []}
                  getOptionLabel={(option: any) => option?.label}
                  isOptionEqualToValue={(option, value) =>
                    option?.program_index === value?.program_index
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Programs"
                      label={"Programs"}
                      error={programErrorCreateClient}
                      helperText={programErrorMessageCreateClient}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {addClientError && (
              <Grid
                container
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography sx={{ color: "red" }}>
                  {addClientErrorMessage}
                </Typography>
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid" }}>
          <Button
            variant="outlined"
            onClick={() => handleCloseCreateClientDialog("")}
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "red",
              color: "black",
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="outlined"
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "rgba(59, 147, 74, 1)",
              color: "white",
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateClientDialog;
