import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { SyntheticEvent, useEffect, useState } from "react";
import Spinner from "../../../../components/reusableComponents/spinner/spinner";
import { UserInfo } from "../../../../types/formTypes";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getClientsPrograms,
  getClientsSalesRep,
} from "../../../../store/thunks/portalManagementThunk";
import { addUser } from "../../../../store/thunks/systemManagementThunk";

interface CreateUserDialogProps {
  open: any;
  handleCreateClose: any;
}

const CreateUserDialog = (props: CreateUserDialogProps) => {
  const dispatch = useAppDispatch();
  const { open, handleCreateClose } = props;
  const { allClients, allRoles, clientType, addUserLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );
  let createUserInitialValues: UserInfo = {
    email: "",
    first_name: "",
    last_name: "",
    user_role: undefined,
    client: undefined,
    clients: undefined,
    client_type: undefined,
    sale_rep: undefined,
  };

  const [showCreateUserClient, setShowCreateUserClient] = useState(false);
  const [showCreateUserClients, setShowCreateUserClients] = useState(false);

  const [showCreateUserClientType, setShowCreateUserClientType] =
    useState(false);
  const [showCreateUserSaleRep, setShowCreateUserSaleRep] = useState(false);

  const [clientTypeListCreateUser, setClientTypeListCreateUser] = useState([]);
  const [saleRepListCreateUser, setSaleRepListCreateUser] = useState([]);

  const [roleErrorCreateUser, setRoleErrorCreateUser] = useState(false);
  const [roleErrorMessageCreateUser, setRoleErrorMessageCreateUser] =
    useState("");

  const [clientErrorCreateUser, setClientErrorCreateUser] = useState(false);
  const [clientErrorMessageCreateUser, setClientErrorMessageCreateUser] =
    useState("");

  const [clientTypeErrorCreateUser, setClientTypeErrorCreateUser] =
    useState(false);
  const [
    clientTypeErrorMessageCreateUser,
    setClientTypeErrorMessageCreateUser,
  ] = useState("");

  const [saleRepErrorCreateUser, setSaleRepErrorCreateUser] = useState(false);
  const [saleRepErrorMessageCreateUser, setSaleRepErrorMessageCreateUser] =
    useState("");

  const [addUserError, setAddUserError] = useState(false);
  const [addUserErrorMessage, setAddUserErrorMessage] = useState("");

  const validateAddUser = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is Required"),
    first_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name is Required"),
    last_name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Last Name is Required"),
    user_role: Yup.mixed(),
    client: Yup.mixed(),
    clients: Yup.mixed(),
    client_type: Yup.mixed(),
    sale_rep: Yup.mixed(),
  });

  const addUserForm = useFormik({
    initialValues: createUserInitialValues,
    validationSchema: validateAddUser,
    enableReinitialize: true,
    onSubmit: (values: UserInfo) => {
      let createUserPayload: any;
      setRoleErrorCreateUser(false);
      setRoleErrorMessageCreateUser("");
      setClientErrorCreateUser(false);
      setClientErrorMessageCreateUser("");
      setClientTypeErrorCreateUser(false);
      setClientTypeErrorMessageCreateUser("");
      setSaleRepErrorCreateUser(false);
      setSaleRepErrorMessageCreateUser("");
      setAddUserError(false);
      setAddUserErrorMessage("");
      if (values.user_role) {
        if (values.user_role?.role_index === 103) {
          if (values.client) {
            if (values.client_type) {
              if (values.client_type.client_type_index === 100) {
                if (values.sale_rep) {
                  createUserPayload = {
                    email: values.email,
                    role: values.user_role.role_index,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    client: values.client.client_index,
                    client_type: values.client_type.client_type_index,
                    sale_rep: values.sale_rep.SALESMAN,
                  };
                } else {
                  setSaleRepErrorCreateUser(true);
                  setSaleRepErrorMessageCreateUser("Sale Rep is required");
                }
              } else {
                createUserPayload = {
                  email: values.email,
                  role: values.user_role.role_index,
                  first_name: values.first_name,
                  last_name: values.last_name,
                  client: values.client.client_index,
                  client_type: values.client_type.client_type_index,
                };
              }
            } else {
              setClientTypeErrorCreateUser(true);
              setClientTypeErrorMessageCreateUser("Client Type is required");
            }
          } else {
            setClientErrorCreateUser(true);
            setClientErrorMessageCreateUser("Client is requied");
            return;
          }
        } else if (
          values.user_role?.role_index === 101 ||
          values.user_role?.role_index === 102
        ) {
          if (values.clients) {
            createUserPayload = {
              email: values.email,
              role: values.user_role.role_index,
              first_name: values.first_name,
              last_name: values.last_name,
              client: values.clients.map((client: any) => client.client_index),
            };
          } else {
            setClientErrorCreateUser(true);
            setClientErrorMessageCreateUser("Client is requied");
            return;
          }
        } else {
          createUserPayload = {
            email: values.email,
            role: values.user_role.role_index,
            first_name: values.first_name,
            last_name: values.last_name,
          };
        }
      } else {
        setRoleErrorCreateUser(true);
        setRoleErrorMessageCreateUser("User Role is required");
        return;
      }

      dispatch(addUser({ createUserPayload: createUserPayload })).then(
        (result: any) => {
          if (result.payload.result === true) {
            handleCloseCreateUserDialog(result.payload.message);
          } else {
            setAddUserError(true);
            setAddUserErrorMessage(result.payload.message);
          }
        }
      );
    },
  });

  const handleCloseCreateUserDialog = (message: string, reason?: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setShowCreateUserClient(false);
    setShowCreateUserClients(false);
    setShowCreateUserClientType(false);
    setShowCreateUserSaleRep(false);
    setRoleErrorCreateUser(false);
    setRoleErrorMessageCreateUser("");
    setClientErrorCreateUser(false);
    setClientErrorMessageCreateUser("");
    setClientTypeErrorCreateUser(false);
    setClientTypeErrorMessageCreateUser("");
    setSaleRepErrorCreateUser(false);
    setSaleRepErrorMessageCreateUser("");
    addUserForm.resetForm();
    handleCreateClose(message);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={(event, reason) => handleCloseCreateUserDialog("", reason)}
      open={open}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgba(59, 147, 74, 1)",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "white" }}>
          Add User
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleCloseCreateUserDialog("")}
          sx={{
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Spinner loading={addUserLoading} />
      <form onSubmit={addUserForm.handleSubmit}>
        <DialogContent>
          <DialogContentText>
            <Grid container item xs={12} sx={{ padding: "20px" }}>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{}}
                  placeholder="Email"
                  label="Email"
                  id="email"
                  name="email"
                  variant="outlined"
                  inputProps={{}}
                  fullWidth
                  value={addUserForm.values.email}
                  error={
                    addUserForm.touched.email &&
                    Boolean(addUserForm.errors.email)
                  }
                  helperText={addUserForm.errors.email}
                  onChange={addUserForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{
                    "& .MuiInputBase-input": {},
                  }}
                  placeholder="First Name"
                  label="First Name"
                  id="first_name"
                  name="first_name"
                  variant="outlined"
                  inputProps={{ style: {} }}
                  fullWidth
                  InputLabelProps={{
                    style: {},
                  }}
                  value={addUserForm.values.first_name}
                  error={
                    addUserForm.touched.first_name &&
                    Boolean(addUserForm.errors.first_name)
                  }
                  helperText={addUserForm.errors.first_name}
                  onChange={addUserForm.handleChange}
                />
              </Grid>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <TextField
                  sx={{}}
                  placeholder="last Name"
                  label="Last Name"
                  id="last_name"
                  name="last_name"
                  variant="outlined"
                  inputProps={{}}
                  fullWidth
                  value={addUserForm.values.last_name}
                  error={
                    addUserForm.touched.last_name &&
                    Boolean(addUserForm.errors.last_name)
                  }
                  helperText={addUserForm.errors.last_name}
                  onChange={addUserForm.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sx={{ padding: "20px" }}>
              <Grid item xs={4} sx={{ padding: "5px" }}>
                <Autocomplete
                  id="role"
                  value={addUserForm.values.user_role}
                  onChange={(_: SyntheticEvent, newValue: any | null) => {
                    setShowCreateUserClientType(false);
                    setShowCreateUserSaleRep(false);
                    setRoleErrorCreateUser(false);
                    setRoleErrorMessageCreateUser("");
                    setClientErrorCreateUser(false);
                    setClientErrorMessageCreateUser("");
                    setClientTypeErrorCreateUser(false);
                    setClientTypeErrorMessageCreateUser("");
                    setSaleRepErrorCreateUser(false);
                    setSaleRepErrorMessageCreateUser("");
                    setAddUserError(false);
                    setAddUserErrorMessage("");
                    if (newValue) {
                      if (newValue.role_index !== 100) {
                        if (newValue.role_index !== 103) {
                          addUserForm.setFieldValue("client", undefined);
                          setShowCreateUserClients(true);
                          setShowCreateUserClient(false);
                        } else {
                          addUserForm.setFieldValue("clients", undefined);
                          setShowCreateUserClients(false);
                          setShowCreateUserClient(true);
                        }
                      } else {
                        addUserForm.setFieldValue("clients", undefined);
                        addUserForm.setFieldValue("client", undefined);
                        setShowCreateUserClients(false);
                        setShowCreateUserClient(false);
                      }
                      addUserForm.setFieldValue("user_role", newValue);
                    } else {
                      addUserForm.setFieldValue("clients", undefined);
                      addUserForm.setFieldValue("client", undefined);
                      setShowCreateUserClient(false);
                      setShowCreateUserClients(false);
                      addUserForm.setFieldValue("user_role", undefined);
                    }
                  }}
                  options={allRoles ? allRoles : []}
                  getOptionLabel={(option: any) => option.role_label}
                  isOptionEqualToValue={(option, value) =>
                    option.role_index === value.role_index
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Role"
                      label={"Role"}
                      error={roleErrorCreateUser}
                      helperText={roleErrorMessageCreateUser}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8} sx={{ padding: "5px" }}>
                {showCreateUserClients && (
                  <Autocomplete
                    multiple
                    id="clients"
                    value={addUserForm.values.clients}
                    onChange={(_: SyntheticEvent, newValue: any | null) => {
                      setClientErrorCreateUser(false);
                      setClientErrorMessageCreateUser("");
                      setClientTypeErrorCreateUser(false);
                      setClientTypeErrorMessageCreateUser("");
                      setSaleRepErrorCreateUser(false);
                      setSaleRepErrorMessageCreateUser("");
                      setAddUserError(false);
                      setAddUserErrorMessage("");
                      if (newValue) {
                        addUserForm.setFieldValue("clients", newValue);
                      } else {
                        addUserForm.setFieldValue("clients", undefined);
                      }
                    }}
                    options={allClients ? allClients : []}
                    getOptionLabel={(option: any) => option.customer_name}
                    isOptionEqualToValue={(option, value) =>
                      option.client_index === value.client_index
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Clients"
                        label="Clients"
                        error={clientErrorCreateUser}
                        helperText={clientErrorMessageCreateUser}
                      />
                    )}
                  />
                )}
                {showCreateUserClient && (
                  <Autocomplete
                    id="client"
                    value={addUserForm.values.client}
                    onChange={(_: SyntheticEvent, newValue: any | null) => {
                      setShowCreateUserSaleRep(false);
                      setClientErrorCreateUser(false);
                      setClientErrorMessageCreateUser("");
                      setClientTypeErrorCreateUser(false);
                      setClientTypeErrorMessageCreateUser("");
                      setSaleRepErrorCreateUser(false);
                      setSaleRepErrorMessageCreateUser("");
                      setAddUserError(false);
                      setAddUserErrorMessage("");
                      if (newValue) {
                        addUserForm.setFieldValue("client", newValue);
                        dispatch(
                          getClientsPrograms({
                            client_index: newValue.client_index,
                          })
                        ).then((result: any) => {
                          if (result.payload?.programs.length > 0) {
                            let analytic_check = false;
                            result.payload.programs.forEach((program: any) => {
                              if (program.program_index === 100)
                                analytic_check = true;
                            });
                            if (analytic_check) {
                              setClientTypeListCreateUser(clientType);
                            } else {
                              let tempClientList = clientType.filter(
                                (client_type: any) =>
                                  client_type.client_type_index !== 100
                              );
                              setClientTypeListCreateUser(tempClientList);
                            }
                          }
                          setShowCreateUserClientType(true);
                        });
                      } else {
                        setShowCreateUserClientType(false);
                        addUserForm.setFieldValue("client", undefined);
                      }
                    }}
                    options={allClients ? allClients : []}
                    getOptionLabel={(option: any) => option.customer_name}
                    isOptionEqualToValue={(option, value) =>
                      option.client_index === value.client_index
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Client"
                        label="Client"
                        error={clientErrorCreateUser}
                        helperText={clientErrorMessageCreateUser}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} sx={{ padding: "20px" }}>
              {showCreateUserClientType && (
                <Grid item xs={4} sx={{ padding: "5px" }}>
                  <Autocomplete
                    id="client_type"
                    value={addUserForm.values.client_type}
                    onChange={(_: SyntheticEvent, newValue: any | null) => {
                      setShowCreateUserSaleRep(false);
                      setClientTypeErrorCreateUser(false);
                      setClientTypeErrorMessageCreateUser("");
                      setSaleRepErrorCreateUser(false);
                      setSaleRepErrorMessageCreateUser("");
                      setAddUserError(false);
                      setAddUserErrorMessage("");
                      if (newValue) {
                        if (newValue.client_type_index === 100) {
                          dispatch(
                            getClientsSalesRep({
                              customer_id:
                                addUserForm.values.client.customer_id,
                            })
                          ).then((result: any) => {
                            if (result.payload?.salesmen_list.length > 0) {
                              setSaleRepListCreateUser(
                                result.payload?.salesmen_list
                              );
                            } else {
                              setSaleRepListCreateUser([]);
                            }
                            setShowCreateUserSaleRep(true);
                          });
                        } else {
                          setShowCreateUserSaleRep(false);
                        }
                        addUserForm.setFieldValue("client_type", newValue);
                      } else {
                        setShowCreateUserSaleRep(false);
                        addUserForm.setFieldValue("client_type", undefined);
                      }
                    }}
                    options={clientTypeListCreateUser}
                    isOptionEqualToValue={(option, value) =>
                      option.client_type_index === value.client_type_index
                    }
                    getOptionLabel={(option: any) => option.client_type_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Client Type"
                        label="Client Type"
                        error={clientTypeErrorCreateUser}
                        helperText={clientTypeErrorMessageCreateUser}
                      />
                    )}
                  />
                </Grid>
              )}
              {showCreateUserSaleRep && (
                <Grid item xs={8} sx={{ padding: "5px" }}>
                  <Autocomplete
                    id="sale_rep"
                    value={addUserForm.values.sale_rep}
                    onChange={(_: SyntheticEvent, newValue: string | null) => {
                      setSaleRepErrorCreateUser(false);
                      setSaleRepErrorMessageCreateUser("");
                      setAddUserError(false);
                      setAddUserErrorMessage("");
                      if (newValue) {
                        addUserForm.setFieldValue("sale_rep", newValue);
                      } else {
                        addUserForm.setFieldValue("sale_rep", undefined);
                      }
                    }}
                    options={saleRepListCreateUser}
                    getOptionLabel={(option: any) => option.SALESMAN}
                    isOptionEqualToValue={(option, value) =>
                      option.SALESMAN === value.SALESMAN
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Sale Rep"
                        label="Sale Rep"
                        error={saleRepErrorCreateUser}
                        helperText={saleRepErrorMessageCreateUser}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            {addUserError && (
              <Grid sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ color: "red" }}>
                  {addUserErrorMessage}
                </Typography>
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ borderTop: "1px solid" }}>
          <Button
            variant="outlined"
            onClick={() => handleCloseCreateUserDialog("")}
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "red",
              color: "black",
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="outlined"
            sx={{
              padding: "5px 10px 5px 10px",
              backgroundColor: "rgba(59, 147, 74, 1)",
              color: "white",
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateUserDialog;
