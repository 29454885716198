import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import Analytics from "./analytics/analytics";
import Equipments from "./equipments/equipments";
import Learning from "./learning/learning";
import SaleRepView from "./saleRepView/saleRepView";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { getLastUpdated } from "../../store/thunks/portalManagementThunk";

const retentionRateClient = new QueryClient();
const learningClient = new QueryClient();

const AccountView = () => {
  const dispatch = useAppDispatch();
  const { selectedProgram, selectedAccountType, selectedClient } =
    useAppSelector((state: any) => state.portalManagement);

  useEffect(() => {
    dispatch(getLastUpdated({ payload: selectedClient.customer_id }));
  }, []);

  return (
    <Grid container sx={{}}>
      {selectedProgram.program_index === 100 &&
        (selectedAccountType.client_type_index === 100 ? (
          <QueryClientProvider client={retentionRateClient}>
            <SaleRepView />
          </QueryClientProvider>
        ) : (
          <Analytics />
        ))}
      {selectedProgram.program_index === 101 && (
        <QueryClientProvider client={learningClient}>
          <Learning />
        </QueryClientProvider>
      )}
      {selectedProgram.program_index === 103 && <Equipments />}
    </Grid>
  );
};

export default AccountView;
