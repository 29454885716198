import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { LineChart, PieChart } from "@mui/x-charts";
import { styled } from "@mui/material/styles";
import { useAppSelector } from "../../../../hooks/hooks";
import {
  ConvertToObjectList,
  GetArray,
  GetObject,
  WithCommas,
} from "../../../../utils/utils";
import {
  BarChart,
  Bar,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
  TooltipProps,
} from "recharts";
import MiniSpinner from "../../../reusableComponents/miniSpinner/miniSpinner";
import {
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";

const StyledText = styled("text")(({ theme }) => ({
  fill: "rgba(0, 70, 128, 1)",
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 10,
  fontWeight: "bold",
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <Grid
        style={{
          border: "1px solid",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Typography>{label}</Typography>
        <Divider />
        {payload.map((element: any) => (
          <Typography sx={{ color: element.color }}>{`${
            element?.name[0]?.toUpperCase() + element?.name?.slice(1)
          } : ${WithCommas(element.value)}`}</Typography>
        ))}
      </Grid>
    );
  }
  return null;
};

const CustomTooltipPercentage = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <Grid
        style={{
          border: "1px solid",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Typography>{label}</Typography>
        <Divider />
        {payload.map((element: any) => (
          <Typography sx={{ color: element.color }}>{`${
            element?.name[0]?.toUpperCase() + element?.name?.slice(1)
          } : ${WithCommas(element.value)} %`}</Typography>
        ))}
      </Grid>
    );
  }
  return null;
};

const CustomTooltipRevenue = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <Grid
        style={{
          border: "1px solid",
          backgroundColor: "white",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Typography>{label}</Typography>
        <Divider />
        {payload.map((element: any) => (
          <Typography sx={{ color: element.color }}>{`${
            element?.name[0]?.toUpperCase() + element?.name?.slice(1)
          } : $ ${WithCommas(element.value)}`}</Typography>
        ))}
      </Grid>
    );
  }
  return null;
};

const GrowthForecast = () => {
  const {
    forecastData,
    revinvData,
    selectedBranch,
    selectedDepartment,
    atRiskData,
    benchRawData,
    dealerRawData,
    benchWSGRawData,
    benchBranches,
    wsr12RawData,
    surveyRawData,
    activeAccountLoading,
    benchMarkLoading,
    atRiskLoading,
    netPromoterLoading,
    customerSurveyLoading,
    revenueLoading,
    gainLost6RawData,
    gainLost12RawData,
    gainLost36RawData,
    wsr36RawData,
  } = useAppSelector((state: any) => state.portalManagement);

  const [growthForecastAccountGrowth, setGrowthForecastAccountGrowth] =
    useState("");
  const [growthForecastRevenueGrowth, setGrowthForecastRevenueGrowth] =
    useState("");
  const [growthForecastInvoiceGrowth, setGrowthForecastInvoiceGrowth] =
    useState("");
  const [growthForecastActiveAccount, setGrowthForecastActiveAccount] =
    useState("");
  const [growthForecastActiveRevenue, setGrowthForecastActiveRevenue] =
    useState("");
  const [growthForecastActiveInvoice, setGrowthForecastActiveInvoice] =
    useState("");
  const [
    growthForecastChartAccountGrowth,
    setGrowthForecastChartAccountGrowth,
  ] = useState([]);
  const [
    growthForecastChartRevenueGrowth,
    setGrowthForecastChartRevenueGrowth,
  ] = useState([]);
  const [
    growthForecastChartInvoiceGrowth,
    setGrowthForecastChartInvoiceGrowth,
  ] = useState([]);

  const [growthForecastChartLabels, setGrowthForecastChartLabels] = useState(
    []
  );

  const [dealerBenchChartData, setDealerBenchChartData] = useState<any[]>([]);

  const [atRiskCustomerFrequency, setAtRiskCustomerFrequency] = useState<any>({
    chartData: [],
    risk_1: "",
    risk_2: "",
    stable: "",
  });
  const [atRiskRevenueFrequency, setAtRiskRevenueFrequency] = useState<any>({
    chartData: [],
    risk_1: "",
    risk_2: "",
    stable: "",
  });
  const [atRiskTransactionFrequency, setAtRiskTransactionFrequency] =
    useState<any>({
      chartData: [],
      risk_1: "",
      risk_2: "",
      stable: "",
    });

  const [growthForecastSurveyChartData, setGrowthForecastSurveyChartData] =
    useState<any[]>([]);

  const [growthForecastSurveyNegPercent, setGrowthForecastSurveyNegPercent] =
    useState("");
  const [growthForecastSurveyNeuPercent, setGrowthForecastSurveyNeuPercent] =
    useState("");
  const [growthForecastSurveyPosPercent, setGrowthForecastSurveyPosPercent] =
    useState("");
  const [growthForecastSurveyCurrentDate, setGrowthForecastSurveyCurrentDate] =
    useState("");

  const [growthForecastNetPromoterData, setGrowthForecastNetPromoterData] =
    useState<any[]>([]);

  const [growthForecastNetPromoter12m, setGrowthForecastNetPromoter12m] =
    useState("");
  const [growthForecastNetPromoter6m, setGrowthForecastNetPromoter6m] =
    useState("");
  const [growthForecastNetPromoter3m, setGrowthForecastNetPromoter3m] =
    useState("");

  const [growthForecastRetentionData, setGrowthForecastRetentionData] =
    useState<any[]>([]);

  const [growthForecastForecastChartData, setGrowthForecastForecastChartData] =
    useState<any[]>([]);

  const [growthForecastForecastActual, setGrowthForecastForecastActual] =
    useState("");
  const [growthForecastForecastForecast, setGrowthForecastForecastForecast] =
    useState("");
  const [growthForecastForecastGrowth, setGrowthForecastForecastGrowth] =
    useState("");

  const [growthForecastRevPerCustStable, setGrowthForecastRevPerCustStable] =
    useState("");
  const [growthForecastRevPerCustAtrist1, setGrowthForecastRevPerCustAtrist1] =
    useState("");
  const [growthForecastRevPerCustAtrist2, setGrowthForecastRevPerCustAtrist2] =
    useState("");

  const [
    growthForecastForecastActualGrowth,
    setGrowthForecastForecastActualGrowth,
  ] = useState("");

  const [growthForecastLastRetentionRate, setGrowthForecastLastRetentionRate] =
    useState("");

  const [activeForecast, setActiveForecast] = useState("ORDER_FREQUENCY");
  const [activeNetPromoter, setActiveNetPromoter] = useState("SURVEY_RESULT");

  const [activeRetentionGainLost, setActiveRetentionGainLost] =
    useState("RETENTION");

  const handleForecastNav = (value: string) => {
    setActiveForecast(value);
  };
  const handleNetPromoterNav = (value: string) => {
    setActiveNetPromoter(value);
  };
  const handleRetentionGainLost = (value: string) => {
    setActiveRetentionGainLost(value);
  };

  const [monthlyView, setMonthlyView] = useState(false);

  const [
    salesMarketingGainLostChartGainAcc,
    setSalesMarketingGainLostChartGainAcc,
  ] = useState([]);
  const [
    salesMarketingGainLostChartLostAcc,
    setSalesMarketingGainLostChartLostAcc,
  ] = useState([]);

  const [
    salesMarketingActiveAccountChartLabel,
    setsalesMarketingActiveAccountChartLabel,
  ] = useState([]);

  const [
    salesMarketingGainLost6ChartGainAcc,
    setsalesMarketingGainLost6ChartGainAcc,
  ] = useState([]);
  const [
    salesMarketingGainLost6ChartLostAcc,
    setsalesMarketingGainLost6ChartLostAcc,
  ] = useState([]);
  const [
    salesMarketingGainLost12ChartGainAcc,
    setsalesMarketingGainLost12ChartGainAcc,
  ] = useState([]);
  const [
    salesMarketingGainLost12ChartLostAcc,
    setsalesMarketingGainLost12ChartLostAcc,
  ] = useState([]);
  const [
    salesMarketingGainLost36ChartGainAcc,
    setsalesMarketingGainLost36ChartGainAcc,
  ] = useState([]);
  const [
    salesMarketingGainLost36ChartLostAcc,
    setsalesMarketingGainLost36ChartLostAcc,
  ] = useState([]);

  const [
    growthForecastDealerBenchmarkOption,
    setGrowthForecastDealerBenchmarkOption,
  ] = useState<any[]>([]);

  const [selectedDealerBenchmarkOption, setselectedDealerBenchmarkOption] =
    useState<any>({
      label: "all",
      value: "all",
    });

  const handleDealerBenchmarkChange = (selection: any) => {
    if (selection) {
      setselectedDealerBenchmarkOption(selection);
    } else
      setselectedDealerBenchmarkOption({
        label: "all",
        value: "all",
      });
  };

  const growthForecastFilterData = () => {
    if (revinvData && forecastData) {
      const revInvDataFilter = GetObject(
        GetArray(revinvData).filter((item: any) => {
          return (
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
          );
        })
      );
      const forecastDataFilter = GetObject(
        GetArray(forecastData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );
      if (
        Object.keys(revInvDataFilter)?.length > 0 &&
        Object.keys(forecastDataFilter)?.length > 0
      ) {
        if (
          revInvDataFilter?.ACCOUNT_GROWTH &&
          revInvDataFilter?.ACCOUNT_GROWTH?.length ===
            revInvDataFilter?.REVENUE_GROWTH?.length &&
          revInvDataFilter?.ACCOUNT_GROWTH?.length ===
            revInvDataFilter?.INVOICE_GROWTH?.length &&
          revInvDataFilter?.ACCOUNT_GROWTH?.length ===
            revInvDataFilter?.TOTAL_ACCOUNT?.length &&
          revInvDataFilter?.ACCOUNT_GROWTH?.length ===
            revInvDataFilter?.TOTAL_REVENUE?.length &&
          revInvDataFilter?.ACCOUNT_GROWTH?.length ===
            revInvDataFilter?.INVOICE_COUNT?.length
        ) {
          const lastIndex = revInvDataFilter?.ACCOUNT_GROWTH?.length - 1;
          setGrowthForecastAccountGrowth(
            revInvDataFilter?.ACCOUNT_GROWTH[lastIndex]
              ? revInvDataFilter?.ACCOUNT_GROWTH[lastIndex]
              : []
          );
          setGrowthForecastRevenueGrowth(
            revInvDataFilter?.REVENUE_GROWTH[lastIndex]
              ? revInvDataFilter?.REVENUE_GROWTH[lastIndex]
              : []
          );
          setGrowthForecastInvoiceGrowth(
            revInvDataFilter?.INVOICE_GROWTH[lastIndex]
              ? revInvDataFilter?.INVOICE_GROWTH[lastIndex]
              : []
          );
          setGrowthForecastActiveAccount(
            WithCommas(
              revInvDataFilter?.TOTAL_ACCOUNT[lastIndex]
                ? revInvDataFilter?.TOTAL_ACCOUNT[lastIndex]
                : ""
            )
          );
          setGrowthForecastActiveRevenue(
            WithCommas(
              revInvDataFilter?.TOTAL_REVENUE[lastIndex]
                ? revInvDataFilter?.TOTAL_REVENUE[lastIndex]
                : ""
            )
          );
          setGrowthForecastActiveInvoice(
            WithCommas(
              revInvDataFilter?.INVOICE_COUNT[lastIndex]
                ? revInvDataFilter?.INVOICE_COUNT[lastIndex]
                : ""
            )
          );

          setGrowthForecastLastRetentionRate(
            revInvDataFilter.retention_rate[lastIndex]
              ? revInvDataFilter.retention_rate[lastIndex]
              : "-"
          );
        } else {
          setGrowthForecastAccountGrowth("");
          setGrowthForecastRevenueGrowth("");
          setGrowthForecastInvoiceGrowth("");
          setGrowthForecastActiveAccount("");
          setGrowthForecastActiveRevenue("");
          setGrowthForecastActiveInvoice("");
          setGrowthForecastForecastActualGrowth("Not Enough Historical Data");
          setGrowthForecastLastRetentionRate("-");
        }

        setGrowthForecastForecastActualGrowth(
          activeForecast === "ORDER_FREQUENCY"
            ? revInvDataFilter?.INVOICE_GROWTH[
                revInvDataFilter?.INVOICE_GROWTH?.length - 1
              ]
              ? revInvDataFilter?.INVOICE_GROWTH[
                  revInvDataFilter?.INVOICE_GROWTH?.length - 1
                ]
              : ""
            : activeForecast === "ACTIVE_ACCOUNT"
            ? revInvDataFilter?.ACCOUNT_GROWTH[
                revInvDataFilter?.ACCOUNT_GROWTH?.length - 1
              ]
              ? revInvDataFilter?.ACCOUNT_GROWTH[
                  revInvDataFilter?.ACCOUNT_GROWTH?.length - 1
                ]
              : ""
            : revInvDataFilter?.REVENUE_GROWTH[
                revInvDataFilter?.REVENUE_GROWTH?.length - 1
              ]
            ? revInvDataFilter?.REVENUE_GROWTH[
                revInvDataFilter?.REVENUE_GROWTH?.length - 1
              ]
            : ""
        );

        setGrowthForecastChartAccountGrowth(
          revInvDataFilter?.ACCOUNT_GROWTH
            ? revInvDataFilter?.ACCOUNT_GROWTH
            : []
        );
        setGrowthForecastChartRevenueGrowth(
          revInvDataFilter?.REVENUE_GROWTH
            ? revInvDataFilter?.REVENUE_GROWTH
            : []
        );
        setGrowthForecastChartInvoiceGrowth(
          revInvDataFilter?.INVOICE_GROWTH
            ? revInvDataFilter?.INVOICE_GROWTH
            : []
        );
        setGrowthForecastChartLabels(
          revInvDataFilter?.END_PERIOD ? revInvDataFilter?.END_PERIOD : []
        );

        const tempGrowthForecastRetentionData: any[] = [];
        if (
          revInvDataFilter?.END_PERIOD &&
          revInvDataFilter?.END_PERIOD?.length ===
            revInvDataFilter?.retention_rate?.length &&
          revInvDataFilter?.END_PERIOD?.length ===
            revInvDataFilter?.ret_target?.length
        ) {
          revInvDataFilter?.END_PERIOD.forEach((element: any, index: any) => {
            tempGrowthForecastRetentionData.push({
              name: element,
              "Retention Rate": revInvDataFilter?.retention_rate[index],
              "Retention Goal": revInvDataFilter?.ret_target[index],
            });
          });
        }

        setGrowthForecastRetentionData(tempGrowthForecastRetentionData);

        const tempGrowthForecastForecastChartData: any[] = [];

        if (forecastDataFilter.forecast_date) {
          forecastDataFilter.forecast_date.forEach(
            (element: any, index: any) => {
              tempGrowthForecastForecastChartData.push({
                name: element,
                lower:
                  activeForecast === "ORDER_FREQUENCY"
                    ? monthlyView
                      ? forecastDataFilter.forecast_l_freq
                        ? forecastDataFilter.forecast_l_freq[index]
                        : 0
                      : forecastDataFilter.forecast_12_l_freq
                      ? forecastDataFilter.forecast_12_l_freq[index]
                      : 0
                    : activeForecast === "ACTIVE_ACCOUNT"
                    ? monthlyView
                      ? forecastDataFilter.forecast_l_acc
                        ? forecastDataFilter.forecast_l_acc[index]
                        : 0
                      : forecastDataFilter.forecast_12_l_acc
                      ? forecastDataFilter.forecast_12_l_acc[index]
                      : 0
                    : monthlyView
                    ? forecastDataFilter.forecast_l_rev
                      ? forecastDataFilter.forecast_l_rev[index]
                      : 0
                    : forecastDataFilter.forecast_12_l_rev
                    ? forecastDataFilter.forecast_12_l_rev[index]
                    : 0,
                actual:
                  activeForecast === "ORDER_FREQUENCY"
                    ? monthlyView
                      ? forecastDataFilter.actual_frequency
                        ? forecastDataFilter.actual_frequency[index]
                        : 0
                      : forecastDataFilter.actual_12_freq
                      ? forecastDataFilter.actual_12_freq[index]
                      : 0
                    : activeForecast === "ACTIVE_ACCOUNT"
                    ? monthlyView
                      ? forecastDataFilter.actual_account
                        ? forecastDataFilter.actual_account[index]
                        : 0
                      : forecastDataFilter.actual_12_acc
                      ? forecastDataFilter.actual_12_acc[index]
                      : 0
                    : monthlyView
                    ? forecastDataFilter.actual_revenue
                      ? forecastDataFilter.actual_revenue[index]
                      : 0
                    : forecastDataFilter.actual_12_rev
                    ? forecastDataFilter.actual_12_rev[index]
                    : 0,
                upper:
                  activeForecast === "ORDER_FREQUENCY"
                    ? monthlyView
                      ? forecastDataFilter.forecast_u_freq
                        ? forecastDataFilter.forecast_u_freq[index]
                        : 0
                      : forecastDataFilter.forecast_12_u_freq
                      ? forecastDataFilter.forecast_12_u_freq[index]
                      : 0
                    : activeForecast === "ACTIVE_ACCOUNT"
                    ? monthlyView
                      ? forecastDataFilter.forecast_u_acc
                        ? forecastDataFilter.forecast_u_acc[index]
                        : 0
                      : forecastDataFilter.forecast_12_u_acc
                      ? forecastDataFilter.forecast_12_u_acc[index]
                      : 0
                    : monthlyView
                    ? forecastDataFilter.forecast_u_rev
                      ? forecastDataFilter.forecast_u_rev[index]
                      : 0
                    : forecastDataFilter.forecast_12_u_rev
                    ? forecastDataFilter.forecast_12_u_rev[index]
                    : 0,
                forecast:
                  activeForecast === "ORDER_FREQUENCY"
                    ? monthlyView
                      ? forecastDataFilter.forecast_freq
                        ? forecastDataFilter.forecast_freq[index]
                        : 0
                      : forecastDataFilter.forecast_12_freq
                      ? forecastDataFilter.forecast_12_freq[index]
                      : 0
                    : activeForecast === "ACTIVE_ACCOUNT"
                    ? monthlyView
                      ? forecastDataFilter.forecast_acc
                        ? forecastDataFilter.forecast_acc[index]
                        : 0
                      : forecastDataFilter.forecast_12_acc
                      ? forecastDataFilter.forecast_12_acc[index]
                      : 0
                    : monthlyView
                    ? forecastDataFilter.forecast_rev
                      ? forecastDataFilter.forecast_rev[index]
                      : 0
                    : forecastDataFilter.forecast_12_rev
                    ? forecastDataFilter.forecast_12_rev[index]
                    : 0,
              });
            }
          );
        }
        setGrowthForecastForecastChartData(tempGrowthForecastForecastChartData);

        setGrowthForecastForecastActual(
          forecastDataFilter.actual_12_freq &&
            forecastDataFilter.actual_12_freq?.length >= 11 &&
            activeForecast === "ORDER_FREQUENCY"
            ? WithCommas(
                forecastDataFilter.actual_12_freq &&
                  forecastDataFilter.actual_12_freq?.length >= 11
                  ? forecastDataFilter.actual_12_freq[11]
                  : ""
              )
            : forecastDataFilter.actual_12_acc &&
              forecastDataFilter.actual_12_acc?.length >= 11 &&
              activeForecast === "ACTIVE_ACCOUNT"
            ? WithCommas(
                forecastDataFilter.actual_12_acc &&
                  forecastDataFilter.actual_12_acc?.length >= 11
                  ? forecastDataFilter.actual_12_acc[11]
                  : ""
              )
            : forecastDataFilter.actual_12_rev &&
              forecastDataFilter.actual_12_rev?.length >= 11
            ? "$" +
              WithCommas(
                forecastDataFilter.actual_12_rev &&
                  forecastDataFilter.actual_12_rev?.length >= 11
                  ? forecastDataFilter.actual_12_rev[11]
                  : ""
              )
            : ""
        );
        setGrowthForecastForecastForecast(
          forecastDataFilter.forecast_12_freq &&
            activeForecast === "ORDER_FREQUENCY"
            ? WithCommas(
                forecastDataFilter.forecast_12_freq &&
                  forecastDataFilter.forecast_12_freq?.length >= 23
                  ? forecastDataFilter.forecast_12_freq[23]
                  : ""
              )
            : forecastDataFilter.forecast_12_acc &&
              activeForecast === "ACTIVE_ACCOUNT"
            ? WithCommas(
                forecastDataFilter.forecast_12_acc &&
                  forecastDataFilter.forecast_12_acc?.length >= 23
                  ? forecastDataFilter.forecast_12_acc[23]
                  : ""
              )
            : forecastDataFilter.forecast_12_rev
            ? "$" +
              WithCommas(
                forecastDataFilter.forecast_12_rev &&
                  forecastDataFilter.forecast_12_rev?.length >= 23
                  ? forecastDataFilter.forecast_12_rev[23]
                  : ""
              )
            : ""
        );
        setGrowthForecastForecastGrowth(
          activeForecast === "ORDER_FREQUENCY"
            ? forecastDataFilter.freq_growth &&
              forecastDataFilter.freq_growth?.length >= 23
              ? forecastDataFilter.freq_growth[23]
              : ""
            : activeForecast === "ACTIVE_ACCOUNT"
            ? forecastDataFilter.acc_growth &&
              forecastDataFilter.acc_growth?.length >= 23
              ? forecastDataFilter.acc_growth[23]
              : ""
            : forecastDataFilter.rev_growth &&
              forecastDataFilter.rev_growth?.length >= 23
            ? forecastDataFilter.rev_growth[23]
            : ""
        );

        setSalesMarketingGainLostChartGainAcc(revInvDataFilter.GAINED_ACCOUNT);
        setSalesMarketingGainLostChartLostAcc(revInvDataFilter.LOST_ACCOUNT);
        setsalesMarketingActiveAccountChartLabel(revInvDataFilter?.END_PERIOD);
      } else {
        setGrowthForecastChartAccountGrowth([]);
        setGrowthForecastChartRevenueGrowth([]);
        setGrowthForecastChartInvoiceGrowth([]);
        setGrowthForecastChartLabels([]);

        setGrowthForecastForecastActual("");
        setGrowthForecastForecastForecast("");
        setGrowthForecastForecastGrowth("Not Enough Historical Data");

        setSalesMarketingGainLostChartGainAcc([]);
        setSalesMarketingGainLostChartLostAcc([]);
        setsalesMarketingActiveAccountChartLabel([]);
      }
    }
    if (benchRawData) {
      let completeOptions = benchRawData?.dealer_type
        ? ConvertToObjectList(benchRawData?.dealer_type)
        : [];

      setGrowthForecastDealerBenchmarkOption(
        completeOptions.filter((obj: any, index: any) => {
          return (
            index ===
            completeOptions.findIndex((o: any) => obj.value === o.value)
          );
        })
      );

      const salrankFilter = GetObject(
        GetArray(benchRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.dealer_type === selectedDealerBenchmarkOption.value
        )
      );

      let tempDealerBenchChartData: any[] = [];
      let tempDuplicate: any[] = [];
      if (
        salrankFilter?.key_metric &&
        salrankFilter?.key_metric?.length ===
          salrankFilter.client_perf?.length &&
        salrankFilter?.key_metric?.length === salrankFilter.avg_per?.length
      ) {
        salrankFilter?.key_metric.forEach((element: any, index: any) => {
          if (!tempDuplicate.includes(element)) {
            tempDealerBenchChartData.push({
              name: element,
              you: salrankFilter.client_perf[index],
              average: salrankFilter.avg_per[index],
            });
            tempDuplicate.push(element);
          }
        });
      }

      setDealerBenchChartData(tempDealerBenchChartData);
    }
    if (atRiskData) {
      const atRiskFilter = GetObject(
        GetArray(atRiskData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );

      if (Object.keys(atRiskFilter)?.length > 0) {
        if (
          atRiskFilter.CUST_FREQ?.length >= 3 &&
          atRiskFilter?.CUST_COUNT?.length >= 3
        ) {
          const customerFrequency = [
            {
              label: `RISK 1: ${
                atRiskFilter?.CUST_FREQ && atRiskFilter?.CUST_FREQ?.length >= 1
                  ? atRiskFilter?.CUST_FREQ[0]
                  : ""
              }%`,
              value: Number(
                atRiskFilter?.CUST_COUNT &&
                  atRiskFilter?.CUST_COUNT?.length >= 1
                  ? atRiskFilter?.CUST_COUNT[0]
                  : 0
              ),
              color: "rgba(49, 144, 242, 1)",
            },
            {
              label: `RISK 2: ${
                atRiskFilter?.CUST_FREQ && atRiskFilter?.CUST_FREQ?.length >= 2
                  ? atRiskFilter?.CUST_FREQ[1]
                  : ""
              }%`,
              value: Number(
                atRiskFilter?.CUST_COUNT &&
                  atRiskFilter?.CUST_COUNT?.length >= 2
                  ? atRiskFilter?.CUST_COUNT[1]
                  : 0
              ),
              color: "rgba(250, 3, 3, 0.8)",
            },
            {
              label: `STABLE: ${
                atRiskFilter?.CUST_FREQ && atRiskFilter?.CUST_FREQ?.length >= 3
                  ? atRiskFilter?.CUST_FREQ[2]
                  : ""
              }%`,
              value: Number(
                atRiskFilter?.CUST_COUNT &&
                  atRiskFilter?.CUST_COUNT?.length >= 3
                  ? atRiskFilter?.CUST_COUNT[2]
                  : 0
              ),
              color: "rgba(56, 181, 74, 1)",
            },
          ];
          setAtRiskCustomerFrequency({
            chartData: customerFrequency,
            risk_1:
              atRiskFilter?.CUST_FREQ && atRiskFilter?.CUST_FREQ?.length >= 1
                ? atRiskFilter?.CUST_FREQ[0]
                : 0,
            risk_2:
              atRiskFilter?.CUST_FREQ && atRiskFilter?.CUST_FREQ?.length >= 2
                ? atRiskFilter?.CUST_FREQ[1]
                : 0,
            stable:
              atRiskFilter?.CUST_FREQ && atRiskFilter?.CUST_FREQ?.length >= 3
                ? atRiskFilter?.CUST_FREQ[2]
                : 0,
          });
        }
        if (
          atRiskFilter?.REVENUE_FREQ?.length >= 3 &&
          atRiskFilter?.REVENUE?.length >= 3
        ) {
          const revenueFrequency = [
            {
              label: `RISK 1:${
                atRiskFilter?.REVENUE_FREQ &&
                atRiskFilter?.REVENUE_FREQ?.length >= 1
                  ? atRiskFilter?.REVENUE_FREQ[0]
                  : ""
              } %`,
              value: Number(
                atRiskFilter?.REVENUE && atRiskFilter?.REVENUE?.length >= 1
                  ? atRiskFilter?.REVENUE[0]
                  : 0
              ),
              color: "rgba(49, 144, 242, 1)",
            },
            {
              label: `RISK 2: ${
                atRiskFilter?.REVENUE_FREQ &&
                atRiskFilter?.REVENUE_FREQ?.length >= 2
                  ? atRiskFilter?.REVENUE_FREQ[1]
                  : 0
              } %`,
              value: Number(
                atRiskFilter?.REVENUE && atRiskFilter?.REVENUE?.length >= 2
                  ? atRiskFilter?.REVENUE[1]
                  : 0
              ),
              color: "rgba(250, 3, 3, 0.8)",
            },
            {
              label: `STABLE: ${
                atRiskFilter?.REVENUE_FREQ &&
                atRiskFilter?.REVENUE_FREQ?.length >= 3
                  ? atRiskFilter?.REVENUE_FREQ[2]
                  : ""
              } %`,
              value:
                atRiskFilter?.REVENUE && atRiskFilter?.REVENUE?.length >= 3
                  ? atRiskFilter?.REVENUE[2]
                  : 0,
              color: "rgba(56, 181, 74, 1)",
            },
          ];
          setAtRiskRevenueFrequency({
            chartData: revenueFrequency,
            risk_1:
              atRiskFilter?.REVENUE_FREQ &&
              atRiskFilter?.REVENUE_FREQ?.length >= 1
                ? atRiskFilter?.REVENUE_FREQ[0]
                : 0,
            risk_2:
              atRiskFilter?.REVENUE_FREQ &&
              atRiskFilter?.REVENUE_FREQ?.length >= 2
                ? atRiskFilter?.REVENUE_FREQ[1]
                : 0,
            stable:
              atRiskFilter?.REVENUE_FREQ &&
              atRiskFilter?.REVENUE_FREQ?.length >= 3
                ? atRiskFilter?.REVENUE_FREQ[2]
                : 0,
          });
        }

        if (
          atRiskFilter?.ORDER_FREQ?.length >= 3 &&
          atRiskFilter?.ORDER_FREQUENCY?.length >= 3
        ) {
          const transactionFrequency = [
            {
              label: `RISK 1: ${
                atRiskFilter?.ORDER_FREQ &&
                atRiskFilter?.ORDER_FREQ?.length >= 1
                  ? atRiskFilter?.ORDER_FREQ[0]
                  : ""
              }%`,
              value: Number(
                atRiskFilter?.ORDER_FREQUENCY &&
                  atRiskFilter?.ORDER_FREQUENCY?.length >= 1
                  ? atRiskFilter?.ORDER_FREQUENCY[0]
                  : 0
              ),
              color: "rgba(49, 144, 242, 1)",
            },
            {
              label: `RISK 2: ${
                atRiskFilter?.ORDER_FREQ &&
                atRiskFilter?.ORDER_FREQ?.length >= 2
                  ? atRiskFilter?.ORDER_FREQ[1]
                  : 0
              }%`,
              value: Number(
                atRiskFilter?.ORDER_FREQUENCY &&
                  atRiskFilter?.ORDER_FREQUENCY?.length >= 2
                  ? atRiskFilter?.ORDER_FREQUENCY[1]
                  : 0
              ),
              color: "rgba(250, 3, 3, 0.8)",
            },
            {
              label: `STABLE: ${
                atRiskFilter?.ORDER_FREQ &&
                atRiskFilter?.ORDER_FREQ?.length >= 3
                  ? atRiskFilter?.ORDER_FREQ[2]
                  : ""
              }%`,
              value:
                atRiskFilter?.ORDER_FREQUENCY &&
                atRiskFilter?.ORDER_FREQUENCY?.length >= 3
                  ? atRiskFilter?.ORDER_FREQUENCY[2]
                  : 0,
              color: "rgba(56, 181, 74, 1)",
            },
          ];

          setAtRiskTransactionFrequency({
            chartData: transactionFrequency,
            risk_1:
              atRiskFilter?.ORDER_FREQ && atRiskFilter?.ORDER_FREQ?.length >= 1
                ? atRiskFilter?.ORDER_FREQ[0]
                : 0,
            risk_2:
              atRiskFilter?.ORDER_FREQ && atRiskFilter?.ORDER_FREQ?.length >= 2
                ? atRiskFilter?.ORDER_FREQ[1]
                : 0,
            stable:
              atRiskFilter?.ORDER_FREQ && atRiskFilter?.ORDER_FREQ?.length >= 3
                ? atRiskFilter?.ORDER_FREQ[2]
                : 0,
          });
        }
        // setAtRiskChartLabels(atRiskFilter?.AT_RISK_STATUS);
        if (atRiskFilter?.REV_PER_CUST?.length >= 3) {
          setGrowthForecastRevPerCustStable(
            atRiskFilter?.REV_PER_CUST &&
              atRiskFilter?.REV_PER_CUST?.length >= 3
              ? atRiskFilter?.REV_PER_CUST[2]
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0
          );
          setGrowthForecastRevPerCustAtrist1(
            atRiskFilter?.REV_PER_CUST &&
              atRiskFilter?.REV_PER_CUST?.length >= 1
              ? atRiskFilter?.REV_PER_CUST[0]
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0
          );
          setGrowthForecastRevPerCustAtrist2(
            atRiskFilter?.REV_PER_CUST &&
              atRiskFilter?.REV_PER_CUST?.length >= 2
              ? atRiskFilter?.REV_PER_CUST[1]
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0
          );
        }
      } else {
        // setAtRiskChartLabels([]);
        setAtRiskCustomerFrequency({
          chatData: [],
          risk_1: "",
          risk_2: "",
          stable: "",
        });
        setAtRiskRevenueFrequency({
          chatData: [],
          risk_1: "",
          risk_2: "",
          stable: "",
        });
      }
    }
    if (wsr12RawData) {
      const wsrRawDataFilter = GetObject(
        GetArray(wsr12RawData).filter(
          (item: any) => item.BRANCH === selectedBranch.value
        )
      );

      if (Object.keys(wsrRawDataFilter)?.length > 0) {
        // const lastIndex = wsrRawDataFilter.END_PERIOD.length - 1;

        const tempGrowthForecastSurveyChartData: any[] = [];
        const tempGrowthForecastNetPromoterData: any[] = [];
        if (
          wsrRawDataFilter?.wsr_date &&
          wsrRawDataFilter?.wsr_date?.length ===
            wsrRawDataFilter?.neg_percent?.length &&
          wsrRawDataFilter?.wsr_date?.length ===
            wsrRawDataFilter?.neutral_percent?.length &&
          wsrRawDataFilter?.wsr_date?.length ===
            wsrRawDataFilter?.pos_percent?.length &&
          wsrRawDataFilter?.wsr_date?.length ===
            wsrRawDataFilter?.wsr_target?.length &&
          wsrRawDataFilter?.wsr_date?.length === wsrRawDataFilter?.wsr?.length
        ) {
          wsrRawDataFilter.wsr_date.forEach((element: any, index: any) => {
            tempGrowthForecastSurveyChartData.push({
              name: element,
              "0 - 6": wsrRawDataFilter.neg_percent[index],
              "7 & 8": wsrRawDataFilter.neutral_percent[index],
              "9 & 10": wsrRawDataFilter.pos_percent[index],
            });
            tempGrowthForecastNetPromoterData.push({
              name: element,
              "NPS GOAL": wsrRawDataFilter.wsr_target[index],
              NPS: wsrRawDataFilter.wsr[index],
            });
          });
        }
        setGrowthForecastSurveyChartData(tempGrowthForecastSurveyChartData);
        setGrowthForecastNetPromoterData(tempGrowthForecastNetPromoterData);

        setGrowthForecastSurveyNegPercent(
          wsrRawDataFilter.neg_percent[wsrRawDataFilter.neg_percent?.length - 1]
            ? wsrRawDataFilter.neg_percent[
                wsrRawDataFilter.neg_percent?.length - 1
              ]
            : ""
        );
        setGrowthForecastSurveyNeuPercent(
          wsrRawDataFilter.neutral_percent[
            wsrRawDataFilter.neg_percent?.length - 1
          ]
            ? wsrRawDataFilter.neutral_percent[
                wsrRawDataFilter.neg_percent?.length - 1
              ]
            : ""
        );
        setGrowthForecastSurveyPosPercent(
          wsrRawDataFilter.pos_percent[wsrRawDataFilter.pos_percent?.length - 1]
            ? wsrRawDataFilter.pos_percent[
                wsrRawDataFilter.pos_percent?.length - 1
              ]
            : ""
        );
        setGrowthForecastSurveyCurrentDate(
          wsrRawDataFilter.wsr_date[wsrRawDataFilter.wsr_date?.length - 1]
            ? wsrRawDataFilter.wsr_date[wsrRawDataFilter.wsr_date?.length - 1]
            : []
        );
      } else {
        setGrowthForecastSurveyChartData([]);
        setGrowthForecastNetPromoterData([]);
      }
    }

    if (wsr36RawData) {
      const wsr36Filter = GetObject(
        GetArray(wsr36RawData).filter(
          (item: any) => item.BRANCH === selectedBranch.value
          // &&
          // item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );

      setGrowthForecastNetPromoter12m(wsr36Filter?.wsr_12m?.at(-1));
      setGrowthForecastNetPromoter6m(wsr36Filter?.wsr_6m?.at(-1));
      setGrowthForecastNetPromoter3m(wsr36Filter?.wsr_3m?.at(-1));
    }

    if (gainLost6RawData) {
      const gl6Filter = GetObject(
        GetArray(gainLost6RawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );

      if (Object.keys(gl6Filter)?.length > 0) {
        setsalesMarketingGainLost6ChartGainAcc(
          WithCommas(gl6Filter.GAINED_ACCOUNT)
        );
        setsalesMarketingGainLost6ChartLostAcc(
          WithCommas(gl6Filter.LOST_ACCOUNT)
        );
      } else {
        setsalesMarketingGainLost6ChartGainAcc([]);
        setsalesMarketingGainLost6ChartLostAcc([]);
      }
    }

    if (gainLost12RawData) {
      const gl12Filter = GetObject(
        GetArray(gainLost12RawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );

      if (Object.keys(gl12Filter)?.length > 0) {
        setsalesMarketingGainLost12ChartGainAcc(
          WithCommas(gl12Filter.GAINED_ACCOUNT)
        );
        setsalesMarketingGainLost12ChartLostAcc(
          WithCommas(gl12Filter.LOST_ACCOUNT)
        );
      } else {
        setsalesMarketingGainLost12ChartGainAcc([]);
        setsalesMarketingGainLost12ChartLostAcc([]);
      }
    }

    if (gainLost36RawData) {
      const gl36Filter = GetObject(
        GetArray(gainLost36RawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );

      if (Object.keys(gl36Filter)?.length > 0) {
        setsalesMarketingGainLost36ChartGainAcc(
          WithCommas(gl36Filter.GAINED_ACCOUNT)
        );
        setsalesMarketingGainLost36ChartLostAcc(
          WithCommas(gl36Filter.LOST_ACCOUNT)
        );
      } else {
        setsalesMarketingGainLost36ChartGainAcc([]);
        setsalesMarketingGainLost36ChartLostAcc([]);
      }
    }
  };

  useEffect(() => {
    growthForecastFilterData();
  }, [
    forecastData,
    revinvData,
    selectedBranch,
    selectedDepartment,
    atRiskData,
    benchRawData,
    dealerRawData,
    benchWSGRawData,
    benchBranches,
    wsr12RawData,
    surveyRawData,
    activeForecast,
    monthlyView,
    wsr36RawData,
    selectedDealerBenchmarkOption,
  ]);

  const tickFormatter = (value: string) => {
    const details = value.split(" ");
    let info = "";
    if (details.length > 2) {
      details.forEach((element: any) => {
        info += `${element}\n`;
      });
      return info;
    } else return value;
  };

  return (
    <Grid container sx={{}}>
      <Grid
        container
        sx={{
          padding: "0px 20px 10px 20px",
        }}
      >
        <Grid container sx={{}}>
          <Grid container item xs={6} sx={{ padding: "10px" }}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  backgroundColor: "#38B54A",
                  display: "flex",
                  padding: "5px 0px 5px 20px",
                  alignItems: "center",
                  borderRadius: "5px 5px 0px 0px",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  Rolling 12 Months Growth Rate
                </Typography>
              </Grid>
              {activeAccountLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    padding: "10px 20px 10px 20px",
                    border: "1px solid grey",
                    backgroundColor: "#FFFFFF",
                    gap: 2,
                  }}
                >
                  <Grid container item xs={12} sx={{}}>
                    <Grid container item xs={3.5} sx={{ padding: "5px" }}>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          backgroundColor: "rgba(247, 249, 252, 1)",
                          padding: "5px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={5}
                          sx={{
                            padding: "0px 0px 0px 5px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            {growthForecastActiveAccount}
                          </Typography>
                          <Typography>Accounts</Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={7}
                          sx={{
                            display: "flex",
                          }}
                        >
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor:
                                Number(growthForecastAccountGrowth) > 0
                                  ? "rgba(235, 253, 243, 1)"
                                  : "rgba(254, 236, 238, 1)",
                              height: "40px",
                              alignItems: "center",
                              padding: "10px",
                              gap: 1,
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            {Number(growthForecastAccountGrowth) > 0 ? (
                              <TrendingUpIcon
                                sx={{ color: "rgba(56, 181, 74, 1)" }}
                              />
                            ) : (
                              <TrendingDownIcon
                                sx={{ color: "rgba(250, 3, 3, 0.8)" }}
                              />
                            )}
                            <Typography
                              variant="h4"
                              sx={{
                                fontWeight: "bold",
                                color:
                                  Number(growthForecastAccountGrowth) > 0
                                    ? "rgba(56, 181, 74, 1)"
                                    : "rgba(250, 3, 3, 0.8)",
                              }}
                            >
                              {Number(growthForecastAccountGrowth) > 0
                                ? `+${Number(
                                    growthForecastAccountGrowth
                                  ).toFixed(1)}`
                                : Number(growthForecastAccountGrowth).toFixed(
                                    1
                                  )}
                              %
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item xs={4.5} sx={{ padding: "5px" }}>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          backgroundColor: "rgba(247, 249, 252, 1)",
                          padding: "5px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={7}
                          sx={{
                            padding: "0px 0px 0px 10px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            $ {growthForecastActiveRevenue}
                          </Typography>
                          <Typography>Revenue</Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={3}
                          sx={{
                            display: "flex",
                          }}
                        >
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor:
                                Number(growthForecastRevenueGrowth) > 0
                                  ? "rgba(235, 253, 243, 1)"
                                  : "rgba(254, 236, 238, 1)",
                              height: "40px",
                              alignItems: "center",
                              padding: "10px",
                              gap: 1,
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            {Number(growthForecastRevenueGrowth) > 0 ? (
                              <TrendingUpIcon
                                sx={{ color: "rgba(56, 181, 74, 1)" }}
                              />
                            ) : (
                              <TrendingDownIcon
                                sx={{ color: "rgba(250, 3, 3, 0.8)" }}
                              />
                            )}
                            <Typography
                              variant="h4"
                              sx={{
                                fontWeight: "bold",
                                color:
                                  Number(growthForecastRevenueGrowth) > 0
                                    ? "rgba(56, 181, 74, 1)"
                                    : "rgba(250, 3, 3, 0.8)",
                              }}
                            >
                              {Number(growthForecastRevenueGrowth) > 0
                                ? `+${Number(
                                    growthForecastRevenueGrowth
                                  ).toFixed(1)}`
                                : Number(growthForecastRevenueGrowth).toFixed(
                                    1
                                  )}
                              %
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item xs={4} sx={{ padding: "5px" }}>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          backgroundColor: "rgba(247, 249, 252, 1)",
                          padding: "5px",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={7}
                          sx={{
                            padding: "0px 0px 0px 10px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            {growthForecastActiveInvoice}
                          </Typography>
                          <Typography>Transactions</Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={3}
                          sx={{
                            display: "flex",
                          }}
                        >
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor:
                                Number(growthForecastInvoiceGrowth) > 0
                                  ? "rgba(235, 253, 243, 1)"
                                  : "rgba(254, 236, 238, 1)",
                              height: "40px",
                              alignItems: "center",
                              padding: "10px",
                              gap: 1,
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            {Number(growthForecastInvoiceGrowth) > 0 ? (
                              <TrendingUpIcon
                                sx={{ color: "rgba(56, 181, 74, 1)" }}
                              />
                            ) : (
                              <TrendingDownIcon
                                sx={{ color: "rgba(250, 3, 3, 0.8)" }}
                              />
                            )}
                            <Typography
                              variant="h4"
                              sx={{
                                fontWeight: "bold",
                                color:
                                  Number(growthForecastInvoiceGrowth) > 0
                                    ? "rgba(56, 181, 74, 1)"
                                    : "rgba(250, 3, 3, 0.8)",
                              }}
                            >
                              {Number(growthForecastInvoiceGrowth) > 0
                                ? `+${Number(
                                    growthForecastInvoiceGrowth
                                  ).toFixed(1)}`
                                : Number(growthForecastInvoiceGrowth).toFixed(
                                    1
                                  )}
                              %
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid container item xs={4} sx={{}}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="rolling"
                        value={selectedBranch}
                        onChange={(
                          _: SyntheticEvent,
                          newValue: string | null
                        ) => handleRollingChange()}
                        options={[]}
                        isOptionEqualToValue={(option, newValue) => {
                          return option.id === newValue.id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Period"
                            placeholder="Period"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid> */}
                  <Grid container item xs={12} sx={{ height: "350px" }}>
                    <LineChart
                      series={[
                        {
                          data: growthForecastChartAccountGrowth,
                          label: "Active Accounts",
                          color: "rgba(49, 144, 242, 1)",
                          valueFormatter: (v) => (v === null ? "" : `${v} %`),
                        },
                        {
                          data: growthForecastChartRevenueGrowth,
                          label: "Revenue",
                          color: "rgba(56, 181, 74, 1)",
                          valueFormatter: (v) => (v === null ? "" : `${v} %`),
                        },
                        {
                          data: growthForecastChartInvoiceGrowth,
                          label: "Transactions",
                          color: "rgba(0, 70, 128, 1)",
                          valueFormatter: (v) => (v === null ? "" : `${v} %`),
                        },
                        {
                          data:
                            growthForecastChartLabels.length > 0
                              ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                              : [],
                          label: "Zero Growth",
                          color: "red",
                          valueFormatter: (v) => (v === null ? "" : `${v} %`),
                        },
                      ]}
                      xAxis={[
                        {
                          scaleType: "point",
                          data: growthForecastChartLabels,
                          tickPlacement: "middle",
                          tickLabelStyle: {
                            angle: -30,
                            textAnchor: "end",
                            fontSize: 12,
                          },
                        },
                      ]}
                      yAxis={[
                        {
                          label: "Percentage %",
                          scaleType: "linear",
                        },
                      ]}
                      slotProps={{
                        legend: {
                          direction: "row",
                          position: { vertical: "top", horizontal: "right" },
                          padding: 0,
                          itemMarkWidth: 30,
                          itemMarkHeight: 10,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={6} sx={{ padding: "10px" }}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  backgroundColor: "#38B54A",
                  display: "flex",
                  padding: "5px 0px 5px 20px",
                  alignItems: "center",
                  borderRadius: "5px 5px 0px 0px",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  Rolling 12 Months Forecast
                </Typography>
              </Grid>
              {activeAccountLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    padding: "10px 20px 10px 20px",
                    border: "1px solid grey",
                    backgroundColor: "#FFFFFF",
                    gap: 2,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Grid
                      sx={{
                        backgroundColor: "rgba(231, 231, 231, 1)",
                        display: "flex",
                        padding: "2px",
                        borderRadius: "10px",
                      }}
                    >
                      <Grid sx={{ justifyContent: "center", padding: "5px" }}>
                        <Button
                          onClick={() => handleForecastNav("ORDER_FREQUENCY")}
                          variant="outlined"
                          sx={{
                            color: "black",
                            padding: "0px 20px 0px 20px",
                            backgroundColor:
                              activeForecast === "ORDER_FREQUENCY"
                                ? "#FFFFFF"
                                : "rgba(231, 231, 231, 1)",
                          }}
                        >
                          ORDER FREQUENCY
                        </Button>
                      </Grid>
                      <Grid sx={{ justifyContent: "center", padding: "5px" }}>
                        <Button
                          onClick={() => handleForecastNav("ACTIVE_ACCOUNT")}
                          variant="outlined"
                          sx={{
                            color: "black",
                            padding: "0px 20px 0px 20px",
                            backgroundColor:
                              activeForecast === "ACTIVE_ACCOUNT"
                                ? "#FFFFFF"
                                : "rgba(231, 231, 231, 1)",
                          }}
                        >
                          ACTIVE ACCOUNT
                        </Button>
                      </Grid>
                      <Grid sx={{ justifyContent: "center", padding: "5px" }}>
                        <Button
                          variant="outlined"
                          onClick={() => handleForecastNav("REVENUE")}
                          sx={{
                            color: "black",
                            padding: "0px 20px 0px 20px",
                            backgroundColor:
                              activeForecast === "REVENUE"
                                ? "#FFFFFF"
                                : "rgba(231, 231, 231, 1)",
                          }}
                        >
                          REVENUE
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sx={{}}>
                    <Grid container item xs={5} sx={{ padding: "5px" }}>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          backgroundColor: "rgba(247, 249, 252, 1)",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={7}
                          sx={{
                            padding: "0px 0px 0px 5px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography sx={{ fontWeight: "bold" }}>
                            {growthForecastForecastActual}
                          </Typography>
                          <Typography>Current</Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={5}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor:
                                Number(growthForecastForecastActualGrowth) > 0
                                  ? "rgba(235, 253, 243, 1)"
                                  : "rgba(254, 236, 238, 1)",
                              height: "40px",
                              alignItems: "center",
                              padding: "10px",
                              gap: 1,
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            {Number(growthForecastForecastActualGrowth) > 0 ? (
                              <TrendingUpIcon
                                sx={{ color: "rgba(56, 181, 74, 1)" }}
                              />
                            ) : (
                              <TrendingDownIcon
                                sx={{ color: "rgba(250, 3, 3, 0.8)" }}
                              />
                            )}
                            <Typography
                              variant="h4"
                              sx={{
                                fontWeight: "bold",
                                color:
                                  Number(growthForecastForecastActualGrowth) > 0
                                    ? "rgba(56, 181, 74, 1)"
                                    : "rgba(250, 3, 3, 0.8)",
                              }}
                            >
                              {Number(growthForecastForecastActualGrowth) > 0
                                ? `+${Number(
                                    growthForecastForecastActualGrowth
                                  ).toFixed(1)}`
                                : Number(
                                    growthForecastForecastActualGrowth
                                  ).toFixed(1)}
                              %
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item xs={5} sx={{ padding: "5px" }}>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          backgroundColor: "rgba(247, 249, 252, 1)",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <Grid
                          container
                          item
                          xs={7}
                          sx={{
                            padding: "0px 0px 0px 5px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography sx={{ fontWeight: "bold" }}>
                            {growthForecastForecastForecast}
                          </Typography>
                          <Typography>Projected</Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={5}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "10px",
                          }}
                        >
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor:
                                Number(growthForecastForecastGrowth) > 0
                                  ? "rgba(235, 253, 243, 1)"
                                  : "rgba(254, 236, 238, 1)",
                              height: "40px",
                              alignItems: "center",
                              padding: "10px",
                              gap: 1,
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            {Number(growthForecastForecastGrowth) > 0 ? (
                              <TrendingUpIcon
                                sx={{ color: "rgba(56, 181, 74, 1)" }}
                              />
                            ) : (
                              <TrendingDownIcon
                                sx={{ color: "rgba(250, 3, 3, 0.8)" }}
                              />
                            )}
                            <Typography
                              variant="h4"
                              sx={{
                                fontWeight: "bold",
                                color:
                                  Number(growthForecastForecastGrowth) > 0
                                    ? "rgba(56, 181, 74, 1)"
                                    : "rgba(250, 3, 3, 0.8)",
                              }}
                            >
                              {Number(growthForecastForecastGrowth) > 0
                                ? `+${Number(
                                    growthForecastForecastGrowth
                                  ).toFixed(1)}`
                                : Number(growthForecastForecastGrowth).toFixed(
                                    1
                                  )}
                              %
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item xs={2} sx={{ padding: "5px" }}>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          backgroundColor: "rgba(247, 249, 252, 1)",
                          padding: "10px",
                          borderRadius: "5px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                          &gt; 95%
                        </Typography>
                        <Typography>Confidence</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sx={{}}>
                    <Grid
                      sx={{
                        justifyContent: "center",
                        backgroundColor: "rgba(231, 231, 231, 1)",
                        display: "flex",
                        borderRadius: "10px",
                        padding: "2px",
                      }}
                    >
                      <Grid sx={{ justifyContent: "center", padding: "5px" }}>
                        <Button
                          onClick={() => {
                            setMonthlyView(false);
                          }}
                          variant="outlined"
                          sx={{
                            color: "black",
                            padding: "0px 20px 0px 20px",
                            backgroundColor: monthlyView
                              ? "rgba(231, 231, 231, 1)"
                              : "#FFFFFF",
                          }}
                        >
                          12 Months Rolling View
                        </Button>
                      </Grid>
                      <Grid sx={{ justifyContent: "center", padding: "5px" }}>
                        <Button
                          onClick={() => {
                            setMonthlyView(true);
                          }}
                          variant="outlined"
                          sx={{
                            color: "black",
                            padding: "0px 20px 0px 20px",
                            backgroundColor: monthlyView
                              ? "#FFFFFF"
                              : "rgba(231, 231, 231, 1)",
                          }}
                        >
                          Monthly View
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sx={{ height: "300px" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <ComposedChart
                        data={growthForecastForecastChartData}
                        margin={{
                          top: 10,
                          right: 20,
                          bottom: 60,
                          left: 20,
                        }}
                      >
                        {/* <CartesianGrid stroke="#eee" strokeDasharray="5 5" /> */}
                        <XAxis
                          dataKey="name"
                          scale="auto"
                          angle={-80}
                          tickMargin={25}
                          style={{
                            fontSize: "12px",
                          }}
                          interval={0}
                        />
                        <YAxis
                          tickFormatter={(value) => {
                            if (activeForecast === "REVENUE") {
                              return `$ ${new Intl.NumberFormat("en-US", {
                                notation: "compact",
                                compactDisplay: "short",
                              }).format(value)}`;
                            } else {
                              return value;
                            }
                          }}
                        />
                        <Tooltip
                          content={
                            activeForecast === "REVENUE" ? (
                              <CustomTooltipRevenue />
                            ) : (
                              <CustomTooltip />
                            )
                          }
                        />
                        <Legend verticalAlign="top" />
                        <Bar
                          name="Actual"
                          dataKey="actual"
                          fill="rgba(49, 144, 242, 1)"
                        />
                        <Line
                          name="Lower"
                          dataKey="lower"
                          stroke="rgba(240, 88, 34, 1)"
                        />
                        <Line
                          name="Forecast"
                          dataKey="forecast"
                          stroke="rgba(80, 205, 137, 1)"
                        />
                        <Line
                          name="Upper"
                          dataKey="upper"
                          stroke="rgba(240, 88, 34, 1)"
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{}}>
          <Grid container item xs={6} sx={{ padding: "10px" }}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                sx={{
                  backgroundColor: "#38B54A",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px 0px 5px 20px",
                  borderRadius: "5px 5px 0px 0px",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  Dealer Benchmark Metric Rolling 12 Months
                </Typography>
              </Grid>
              {benchMarkLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    padding: "10px 20px 10px 20px",
                    border: "1px solid grey",
                    backgroundColor: "#FFFFFF",
                    gap: 2,
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ padding: "0px 10% 0px 10%" }}
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <FormControl fullWidth>
                        <Autocomplete
                          id="program"
                          value={selectedDealerBenchmarkOption}
                          onChange={(
                            _: SyntheticEvent,
                            newValue: string | null
                          ) => handleDealerBenchmarkChange(newValue)}
                          options={growthForecastDealerBenchmarkOption}
                          isOptionEqualToValue={(option, newValue) => {
                            return option.value === newValue.value;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Dealer Type"
                              placeholder="Dealer Type"
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sx={{ height: "400px" }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={dealerBenchChartData}
                        margin={{
                          top: 20,
                          right: 0,
                          left: 0,
                          bottom: 120,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="name"
                          angle={-80}
                          tickMargin={70}
                          tickFormatter={tickFormatter}
                        />
                        <YAxis
                          label={{
                            value: "Percentage %",
                            angle: -90,
                            position: "insideLeft",
                          }}
                        />
                        <Tooltip content={<CustomTooltipPercentage />} />
                        <Legend verticalAlign="top" />
                        <Bar
                          dataKey="you"
                          fill="rgba(80, 205, 137, 1)"
                          label={{ position: "top" }}
                        />
                        <Bar
                          dataKey="average"
                          fill="rgba(54, 153, 255, 1)"
                          label={{ position: "top" }}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={6} sx={{ padding: "10px" }}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  backgroundColor: "#38B54A",
                  display: "flex",
                  // justifyContent: "center",
                  padding: "5px 0px 5px 20px",
                  alignItems: "center",
                  borderRadius: "5px 5px 0px 0px",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  At Risk Customer Distrubution
                </Typography>
              </Grid>
              {atRiskLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    padding: "5px 5px 5px 5px",
                    border: "1px solid grey",
                    backgroundColor: "#FFFFFF",
                    gap: 2,
                  }}
                >
                  <Grid container item xs={12} sx={{}}>
                    <Grid container item xs={4} sx={{ padding: "10px" }}>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          backgroundColor: "rgba(90, 238, 158, 0.2)",
                          padding: "10px",
                          borderRadius: "5px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "bold",
                            color: "rgba(56, 181, 74, 1)",
                          }}
                        >
                          STABLE
                        </Typography>
                        <Typography
                          sx={{ color: "rgba(0, 0, 0, 1)", fontWeight: "bold" }}
                        >
                          &lt; 5%
                        </Typography>
                        <Typography>Chance of losing them</Typography>
                        <Typography>
                          $ {growthForecastRevPerCustStable}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={4} sx={{ padding: "10px" }}>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          backgroundColor: "rgba(49, 144, 242, 0.2)",
                          padding: "10px",
                          borderRadius: "5px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "bold",
                            color: "rgba(49, 144, 242, 1)",
                          }}
                        >
                          Risk1:
                        </Typography>
                        <Typography
                          sx={{ color: "rgba(0, 0, 0, 1)", fontWeight: "bold" }}
                        >
                          5% - 50%
                        </Typography>
                        <Typography>Chance of losing them</Typography>
                        <Typography>
                          $ {growthForecastRevPerCustAtrist1}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={4} sx={{ padding: "10px" }}>
                      <Grid
                        container
                        item
                        xs={12}
                        sx={{
                          backgroundColor: "rgba(250, 3, 3, 0.2)",
                          padding: "10px",
                          borderRadius: "5px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "bold",
                            color: "rgba(250, 3, 3, 0.8)",
                          }}
                        >
                          Risk2:
                        </Typography>
                        <Typography
                          sx={{ color: "rgba(0, 0, 0, 1)", fontWeight: "bold" }}
                        >
                          &gt; 50%
                        </Typography>
                        <Typography>Chance of losing them</Typography>
                        <Typography>
                          $ {growthForecastRevPerCustAtrist2}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sx={{}}>
                    <Grid container item xs={4} sx={{ padding: "10px" }}>
                      <Grid
                        container
                        item
                        xs={7}
                        sx={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
                      >
                        <PieChart
                          series={[
                            {
                              data: atRiskCustomerFrequency.chartData,
                              innerRadius: 40,
                              outerRadius: 50,
                            },
                          ]}
                          height={200}
                          slotProps={{
                            legend: {
                              hidden: true,
                            },
                          }}
                          margin={{
                            bottom: 0,
                            left: 0,
                            right: 0,
                            top: 0,
                          }}
                          sx={{
                            borderRadius: "10px 0px 0px 10px",
                          }}
                        >
                          <PieCenterLabel>CUSTOMER #</PieCenterLabel>
                        </PieChart>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={5}
                        sx={{
                          backgroundColor: "rgba(0, 0, 0, 0.1)",
                          borderRadius: "0px 10px 10px 0px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          gap: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "rgba(49, 144, 242, 1)",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          RISK 1: {atRiskCustomerFrequency.risk_1}%
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(250, 3, 3, 0.8)",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          RISK 2: {atRiskCustomerFrequency.risk_2}%
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(56, 181, 74, 1)",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          STABLE: {atRiskCustomerFrequency.stable}%
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={4} sx={{ padding: "10px" }}>
                      <Grid
                        container
                        item
                        xs={7}
                        sx={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
                      >
                        <PieChart
                          series={[
                            {
                              data: atRiskTransactionFrequency.chartData,
                              innerRadius: 40,
                              outerRadius: 50,
                            },
                          ]}
                          height={200}
                          slotProps={{
                            legend: {
                              hidden: true,
                            },
                          }}
                          margin={{
                            bottom: 0,
                            left: 0,
                            right: 0,
                            top: 0,
                          }}
                          sx={{
                            borderRadius: "10px 0px 0px 10px",
                          }}
                        >
                          <PieCenterLabel>TRANSACTION #</PieCenterLabel>
                        </PieChart>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={5}
                        sx={{
                          backgroundColor: "rgba(0, 0, 0, 0.1)",
                          borderRadius: "0px 10px 10px 0px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          gap: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "rgba(49, 144, 242, 1)",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          RISK 1: {atRiskTransactionFrequency.risk_1}%
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(250, 3, 3, 0.8)",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          RISK 2: {atRiskTransactionFrequency.risk_2}%
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(56, 181, 74, 1)",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          STABLE: {atRiskTransactionFrequency.stable}%
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={4}
                      sx={{ padding: "10px", borderRadius: "10px" }}
                    >
                      <Grid
                        container
                        item
                        xs={7}
                        sx={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
                      >
                        <PieChart
                          series={[
                            {
                              data: atRiskRevenueFrequency.chartData,
                              innerRadius: 40,
                              outerRadius: 50,
                              valueFormatter: (v: any) => {
                                console.log(v);

                                if (v) {
                                  return `${v.data} $`;
                                } else return "";
                              },
                            },
                          ]}
                          height={200}
                          slotProps={{
                            legend: {
                              hidden: true,
                            },
                          }}
                          margin={{
                            bottom: 0,
                            left: 0,
                            right: 0,
                            top: 0,
                          }}
                          sx={{
                            borderRadius: "10px 0px 0px 10px",
                          }}
                        >
                          <PieCenterLabel>REVENUE $</PieCenterLabel>
                        </PieChart>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={5}
                        sx={{
                          backgroundColor: "rgba(0, 0, 0, 0.1)",
                          borderRadius: "0px 10px 10px 0px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "rgba(49, 144, 242, 1)",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          RISK 1: {atRiskRevenueFrequency.risk_1}%
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(250, 3, 3, 0.8)",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          RISK 2: {atRiskRevenueFrequency.risk_2}%
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(56, 181, 74, 1)",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          STABLE: {atRiskRevenueFrequency.stable}%
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{}}>
          <Grid container item xs={6} sx={{ padding: "10px" }}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  backgroundColor: "#38B54A",
                  display: "flex",
                  // justifyContent: "center",
                  padding: "5px 0px 5px 20px",
                  alignItems: "center",
                  borderRadius: "5px 5px 0px 0px",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  Net Promoter Score
                </Typography>
              </Grid>
              {customerSurveyLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    padding: "10px 20px 10px 20px",
                    border: "1px solid grey",
                    backgroundColor: "#FFFFFF",
                    gap: 2,
                  }}
                >
                  <Grid container item xs={12} sx={{}}>
                    <Grid container item xs={5} sx={{ padding: "5px" }}>
                      {activeNetPromoter === "SURVEY_RESULT" ? (
                        <Grid
                          container
                          item
                          xs={12}
                          sx={{
                            padding: "10px",
                            borderRadius: "5px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              color: "rgba(0, 0, 0, 1)",
                              fontWeight: "bold",
                            }}
                          >
                            AS OF TODAY
                          </Typography>
                          <Typography sx={{ color: "rgba(0, 0, 0, 1)" }}>
                            GOAL: &gt; 75%
                          </Typography>
                          <Typography
                            sx={{
                              color: "rgba(59, 147, 74, 1)",
                              fontWeight: "bold",
                            }}
                          >
                            RATED 9 & 10
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          item
                          xs={12}
                          sx={{
                            padding: "10px",
                            borderRadius: "5px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "rgba(0, 0, 0, 1)",
                              fontWeight: "bold",
                            }}
                          >
                            NPS GOAL:
                          </Typography>
                          <Typography
                            sx={{
                              color: "rgba(250, 3, 3, 0.8)",
                              fontWeight: "bold",
                            }}
                          >
                            70%
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={7}
                      sx={{ justifyContent: "flex-end" }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Grid
                          sx={{
                            backgroundColor: "rgba(0, 70, 128, 1)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50px",
                            borderRadius: "5px 5px 0px 0px",
                            border: "1px solid grey",
                          }}
                        >
                          <Typography
                            sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                          >
                            {growthForecastSurveyCurrentDate.replace("-", " ")}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          sx={{
                            padding: "10px",
                            border: "1px solid grey",
                            backgroundColor: "rgba(243, 246, 249, 1)",
                          }}
                        >
                          <Grid container item xs={4} sx={{ padding: "5px" }}>
                            <Grid
                              container
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#FFFFFF",
                                padding: "5px",
                                borderRadius: "5px",
                                gap: 1,
                              }}
                            >
                              <Typography>
                                {activeNetPromoter === "SURVEY_RESULT"
                                  ? "0 - 6 :"
                                  : "3 Month:"}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: "rgba(250, 3, 3, 0.8)",
                                }}
                              >
                                {activeNetPromoter === "SURVEY_RESULT"
                                  ? `${growthForecastSurveyNegPercent}%`
                                  : growthForecastNetPromoter3m}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item xs={4} sx={{ padding: "5px" }}>
                            <Grid
                              container
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#FFFFFF",
                                padding: "5px",
                                borderRadius: "5px",
                                gap: 1,
                              }}
                            >
                              <Typography>
                                {activeNetPromoter === "SURVEY_RESULT"
                                  ? "7 & 8 :"
                                  : "6 Month:"}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: "rgba(49, 144, 242, 1)",
                                }}
                              >
                                {activeNetPromoter === "SURVEY_RESULT"
                                  ? `${growthForecastSurveyNeuPercent}%`
                                  : growthForecastNetPromoter6m}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item xs={4} sx={{ padding: "5px" }}>
                            <Grid
                              container
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#FFFFFF",
                                padding: "5px",
                                borderRadius: "5px",
                                gap: 1,
                              }}
                            >
                              <Typography>
                                {activeNetPromoter === "SURVEY_RESULT"
                                  ? "9 & 10 :"
                                  : "12 Month:"}
                              </Typography>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  color: "rgba(56, 181, 74, 1)",
                                }}
                              >
                                {activeNetPromoter === "SURVEY_RESULT"
                                  ? `${growthForecastSurveyPosPercent}%`
                                  : growthForecastNetPromoter12m}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        backgroundColor: "rgba(231, 231, 231, 1)",
                        padding: "2px",
                        borderRadius: "10px",
                      }}
                    >
                      <Grid sx={{ justifyContent: "center", padding: "5px" }}>
                        <Button
                          onClick={() => handleNetPromoterNav("SURVEY_RESULT")}
                          variant="outlined"
                          sx={{
                            color: "black",
                            padding: "0px 20px 0px 20px",
                            backgroundColor:
                              activeNetPromoter === "SURVEY_RESULT"
                                ? "#FFFFFF"
                                : "rgba(231, 231, 231, 1)",
                          }}
                        >
                          SURVEY RESULT
                        </Button>
                      </Grid>
                      <Grid sx={{ justifyContent: "center", padding: "5px" }}>
                        <Button
                          onClick={() => handleNetPromoterNav("NPS_SCORE")}
                          variant="outlined"
                          sx={{
                            color: "black",
                            padding: "0px 20px 0px 20px",
                            backgroundColor:
                              activeNetPromoter === "NPS_SCORE"
                                ? "#FFFFFF"
                                : "rgba(231, 231, 231, 1)",
                          }}
                        >
                          NPS SCORE
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sx={{ height: "300px" }}>
                    {activeNetPromoter === "SURVEY_RESULT" ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          width={500}
                          height={300}
                          data={growthForecastSurveyChartData}
                          margin={{
                            top: 10,
                            right: 0,
                            left: 0,
                            bottom: 40,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="name"
                            scale="auto"
                            angle={-80}
                            tickMargin={25}
                            style={{
                              fontSize: "12px",
                            }}
                            interval={0}
                          />
                          <YAxis />
                          <Tooltip content={<CustomTooltipPercentage />} />
                          <Legend
                            verticalAlign="top"
                            style={{ padding: "10px" }}
                          />
                          <Bar
                            dataKey="0 - 6"
                            stackId="a"
                            fill="rgba(254, 0, 12, 1)"
                          />
                          <Bar
                            dataKey="7 & 8"
                            stackId="a"
                            fill="rgba(0, 163, 225, 1)"
                          />
                          <Bar
                            dataKey="9 & 10"
                            stackId="a"
                            fill="rgba(0, 161, 77, 1)"
                          />
                          <Tooltip content={<CustomTooltipPercentage />} />
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                          data={growthForecastNetPromoterData}
                          margin={{
                            top: 20,
                            right: 0,
                            bottom: 50,
                            left: 0,
                          }}
                        >
                          <CartesianGrid stroke="#f5f5f5" />
                          <XAxis
                            dataKey="name"
                            scale="auto"
                            angle={-80}
                            tickMargin={25}
                            style={{
                              fontSize: "12px",
                            }}
                            interval={0}
                          />
                          <YAxis />
                          <Tooltip content={<CustomTooltipPercentage />} />
                          <Legend
                            verticalAlign="top"
                            // layout="vertical"
                            align="right"
                            wrapperStyle={{
                              paddingBottom: "20px",
                            }}
                          />
                          <Bar
                            dataKey="NPS"
                            barSize={30}
                            fill="rgba(49, 144, 242, 1)"
                            label={{ position: "top" }}
                          />
                          <Line
                            type="monotone"
                            dataKey="NPS GOAL"
                            stroke="rgba(250, 3, 3, 0.8)"
                          />
                        </ComposedChart>
                      </ResponsiveContainer>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={6} sx={{ padding: "10px" }}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  backgroundColor: "#38B54A",
                  display: "flex",
                  // justifyContent: "center",
                  padding: "5px 0px 5px 20px",
                  alignItems: "center",
                  borderRadius: "5px 5px 0px 0px",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  {activeRetentionGainLost === "RETENTION"
                    ? "Retention Rate"
                    : "Gain & Lost Account"}
                </Typography>
              </Grid>
              {revenueLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    padding: "10px 20px 10px 20px",
                    border: "1px solid grey",
                    backgroundColor: "#FFFFFF",
                    gap: 2,
                  }}
                >
                  <Grid container sx={{ justifyContent: "flex-end" }}>
                    <Grid>
                      <Grid
                        sx={{
                          backgroundColor: "rgba(231, 231, 231, 1)",
                          display: "flex",
                          borderRadius: "10px",
                          // padding: "2px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Grid sx={{ padding: "5px" }}>
                          <Button
                            onClick={() => handleRetentionGainLost("RETENTION")}
                            variant="outlined"
                            sx={{
                              fontSize: "10px",
                              color: "black",
                              padding: "0px 10px 0px 20px",
                              backgroundColor:
                                activeRetentionGainLost === "RETENTION"
                                  ? "#FFFFFF"
                                  : "rgba(231, 231, 231, 1)",
                            }}
                          >
                            Retention
                          </Button>
                        </Grid>
                        <Grid sx={{ padding: "5px" }}>
                          <Button
                            onClick={() =>
                              handleRetentionGainLost("GAIN_LOST_ACCOUNT")
                            }
                            variant="outlined"
                            sx={{
                              fontSize: "10px",
                              color: "black",
                              padding: "0px 20px 0px 20px",
                              backgroundColor:
                                activeRetentionGainLost === "GAIN_LOST_ACCOUNT"
                                  ? "#FFFFFF"
                                  : "rgba(231, 231, 231, 1)",
                            }}
                          >
                            GAIN & LOST
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {activeRetentionGainLost === "RETENTION" ? (
                    <Grid container item xs={12} sx={{}}>
                      <Grid container item xs={8} sx={{ padding: "5px" }}>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                        >
                          <Typography sx={{ color: "rgba(135, 135, 135, 1)" }}>
                            Retention drives growth and frequency of purchase.
                          </Typography>
                          <Typography sx={{ color: "rgba(135, 135, 135, 1)" }}>
                            Retention scores &gt; 96% drive growth by &gt; 10%
                            per year
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container item xs={4} sx={{ padding: "5px" }}>
                        <Grid
                          container
                          item
                          xs={12}
                          sx={{
                            backgroundColor: "",
                            borderRadius: "5px",
                            border: "1px solid grey",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "rgba(0, 0, 0, 1)",
                              fontWeight: "bold",
                            }}
                          >
                            RETENTION RATE:
                          </Typography>
                          <Typography
                            variant="h5"
                            sx={{
                              color: "rgba(49, 144, 242, 1)",
                              fontWeight: "bold",
                            }}
                          >
                            {growthForecastLastRetentionRate} %
                          </Typography>
                          <Typography sx={{ color: "rgba(135, 135, 135, 1)" }}>
                            CURRENTLY 12 ROLLING
                          </Typography>
                          <Typography sx={{ color: "rgba(135, 135, 135, 1)" }}>
                            RETENTION RATE
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      // container
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Grid
                          container
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xs={2}>
                            <Typography
                              sx={{
                                fontSize: "25px",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                GAINED:
                              </span>{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              sx={{
                                fontSize: "20px",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                6 MONTH:
                              </span>{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "rgba(59, 147, 74, 1)",
                                }}
                              >
                                {salesMarketingGainLost6ChartGainAcc}
                              </span>{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              sx={{ fontSize: "20px", alignItems: "end" }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                12 MONTH:
                              </span>{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "rgba(59, 147, 74, 1)",
                                }}
                              >
                                {salesMarketingGainLost12ChartGainAcc}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              sx={{ fontSize: "20px", alignItems: "end" }}
                            >
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                36 MONTH:
                              </span>{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "rgba(59, 147, 74, 1)",
                                }}
                              >
                                {salesMarketingGainLost36ChartGainAcc}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xs={2}>
                            <Typography
                              sx={{
                                fontSize: "25px",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>LOST:</span>{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              sx={{
                                fontSize: "20px",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                6 MONTH:
                              </span>{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "rgba(240, 88, 34, 1)",
                                }}
                              >
                                {salesMarketingGainLost6ChartLostAcc}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              sx={{
                                fontSize: "20px",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                12 MONTH:
                              </span>{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "rgba(240, 88, 34, 1)",
                                }}
                              >
                                {salesMarketingGainLost12ChartLostAcc}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              sx={{
                                fontSize: "20px",
                              }}
                            >
                              <span style={{ fontWeight: "bold" }}>
                                36 MONTH:
                              </span>{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                  color: "rgba(240, 88, 34, 1)",
                                }}
                              >
                                {salesMarketingGainLost36ChartLostAcc}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {activeRetentionGainLost === "RETENTION" ? (
                    <Grid container item xs={12} sx={{ height: "350px" }}>
                      {/* <ResponsiveChartContainer
                        series={[
                          {
                            type: "bar",
                            data: growthForecastRetentionRate,
                            color: "rgba(49, 144, 242, 1)",
                          },
                          {
                            type: "line",
                            data: growthForecastRetentionTarget,
                            color: "rgba(250, 3, 3, 0.8)",
                          },
                        ]}
                        xAxis={[
                          {
                            scaleType: "band",
                            data: growthForecastChartLabels,
                            id: "x-axis-id",
                            tickLabelStyle: {
                              angle: -50,
                              textAnchor: "end",
                              fontSize: 12,
                            },
                          },
                        ]}
                        yAxis={[{ id: "linearAxis", scaleType: "linear" }]}
                        margin={{
                          left: 50,
                          right: 0,
                          top: 40,
                          bottom: 70,
                        }}
                      >
                        <BarPlot />
                        <LinePlot />
                        <MarkPlot />
                        <ChartsXAxis
                          position="bottom"
                          axisId="x-axis-id"
                        />
                        <ChartsYAxis
                          label="Active Customers"
                          position="left"
                          axisId="linearAxis"
                        />
                      </ResponsiveChartContainer> */}
                      <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                          data={growthForecastRetentionData}
                          margin={{
                            top: 20,
                            right: 20,
                            bottom: 50,
                            left: 0,
                          }}
                        >
                          <CartesianGrid stroke="#f5f5f5" />
                          <XAxis
                            dataKey="name"
                            scale="auto"
                            angle={-80}
                            tickMargin={25}
                            style={{
                              fontSize: "12px",
                            }}
                            interval={0}
                          />
                          <YAxis />
                          <Tooltip content={<CustomTooltipPercentage />} />
                          <Legend
                            verticalAlign="top"
                            // layout="vertical"
                            align="right"
                            wrapperStyle={{
                              paddingBottom: "20px",
                            }}
                          />
                          <Bar
                            dataKey="Retention Rate"
                            barSize={30}
                            fill="rgba(49, 144, 242, 1)"
                            label={{ position: "top" }}
                          />
                          <Line
                            type="monotone"
                            dataKey="Retention Goal"
                            stroke="rgba(250, 3, 3, 0.8)"
                          />
                        </ComposedChart>
                      </ResponsiveContainer>
                    </Grid>
                  ) : (
                    <Grid container item xs={12} sx={{ height: "300px" }}>
                      <LineChart
                        series={[
                          {
                            data: salesMarketingGainLostChartGainAcc,
                            label: "Gained",
                            color: "rgba(59, 147, 74, 1)",
                          },
                          {
                            data: salesMarketingGainLostChartLostAcc,
                            label: "Lost",
                            color: "rgba(240, 88, 34, 1)",
                          },
                        ]}
                        xAxis={[
                          {
                            scaleType: "point",
                            data: salesMarketingActiveAccountChartLabel,
                            tickLabelStyle: {
                              angle: -50,
                              textAnchor: "end",
                              fontSize: 12,
                            },
                          },
                        ]}
                        slotProps={{
                          legend: {
                            direction: "row",
                            position: { vertical: "top", horizontal: "middle" },
                            padding: 0,
                            itemMarkWidth: 30,
                            itemMarkHeight: 10,
                          },
                        }}
                        margin={{
                          left: 50,
                          right: 30,
                          top: 40,
                          bottom: 70,
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GrowthForecast;
