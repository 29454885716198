import {
  Button,
  Divider,
  Grid,
  ListItemIcon,
  MenuItem,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import TableTemplate from "../../../components/reusableComponents/tableTemplate/tableTemplate";
import { MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getProducts } from "../../../store/thunks/systemManagementThunk";
import CreateProductDialog from "./createProductDialog/createProductDialog";
import EditProductDialog from "./editProductDialog/editProductDialog";
import DeleteProductDialog from "./deleteProductDialog/deleteProductDialog";
import EnableProductDialog from "./enableProductDialog/eneableProduct";

const ProductManagement = () => {
  const dispatch = useAppDispatch();
  const { allProducts, getProductsLoading } = useAppSelector(
    (state: any) => state.systemManagement
  );
  const [openCreateProductDialog, setOpenCreateProductDialog] = useState(false);

  const [editProductRowData, setEditProductRowData] = useState<any>();

  const [openEditProductDialog, setOpenEditProductDialog] = useState(false);
  const [openDeleteProductDialog, setOpenDeleteProductDialog] = useState(false);
  const [openEnableProductDialog, setOpenEnableProductDialog] = useState(false);

  const [productList, setProductList] = useState<any>([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleOpenCreateProduct = () => {
    setOpenCreateProductDialog(true);
  };
  const handleCloseCreateProduct = (message: string) => {
    if (message !== "") {
      setProductList([]);
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setOpenCreateProductDialog(false);
  };

  const handleOpenEditProduct = (rowData: any) => {
    setEditProductRowData(rowData);
    setOpenEditProductDialog(true);
  };
  const handleCloseEditProduct = (message: string) => {
    if (message !== "") {
      setProductList([]);
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setEditProductRowData(null);
    setOpenEditProductDialog(false);
  };

  const handleOpenDeleteProduct = (rowData: any) => {
    setEditProductRowData(rowData);
    setOpenDeleteProductDialog(true);
  };
  const handleCloseDeleteProduct = (message: string) => {
    if (message !== "") {
      setProductList([]);
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setEditProductRowData(null);
    setOpenDeleteProductDialog(false);
  };

  const handleOpenEnableProduct = (rowData: any) => {
    setEditProductRowData(rowData);
    setOpenEnableProductDialog(true);
  };
  const handleCloseEnableProduct = (message: string) => {
    if (message !== "") {
      setProductList([]);
      setSnackbarMessage(message);
      setOpenSnackbar(true);
      refetch();
    }
    setEditProductRowData(null);
    setOpenEnableProductDialog(false);
  };

  const [selectedProductNavigation, setSelectedProductNavigation] =
    useState("active_product");

  const handleProductNavigation = (active_product: any, product_list?: any) => {
    let table_product_list = [];

    if (product_list) {
      if (active_product === "active_product") {
        table_product_list = product_list.filter((product: any) => {
          return product.status === "Active";
        });
      } else {
        table_product_list = product_list.filter((product: any) => {
          return product.status !== "Active";
        });
      }
    } else {
      if (active_product === "active_product") {
        table_product_list = allProducts
          ? allProducts.filter((product: any) => {
              return product.status === "Active";
            })
          : [];
      } else {
        table_product_list = allProducts
          ? allProducts.filter((product: any) => {
              return product.status !== "Active";
            })
          : [];
      }
    }

    setSelectedProductNavigation(active_product);
    setProductList(table_product_list);
  };

  const {
    data: { data = [] } = {},
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<any>({
    queryKey: ["table-data"],
    queryFn: async () => {
      const response: any = await dispatch(getProducts({}))
        .then((result: any) => {
          if (result.payload.products) {
            handleProductNavigation(
              selectedProductNavigation,
              result.payload.products
            );
          }
        })
        .catch((error) => {});
      return response ? response : {};
    },
    placeholderData: keepPreviousData,
  });

  const productColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: "Table",
        header: "",
        columns: [
          {
            accessorKey: "name",
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Product Name",
          },
          {
            accessorKey: "price",
            header: "Price",
          },
          {
            accessorKey: "description",
            filterVariant: "autocomplete",
            header: "Description",
          },
          {
            accessorKey: "product_type",
            enableClickToCopy: true,
            filterVariant: "autocomplete",
            header: "Product Type",
          },
        ],
      },
    ],
    []
  );

  const productTable = useMaterialReactTable({
    columns: productColumns,
    data: productList ? productList : [],
    state: {
      isLoading: getProductsLoading,
    },
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableGrouping: true,
    enableColumnPinning: false,
    enableFacetedValues: true,
    enableRowActions: true,
    enableRowSelection: false,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"],
        right: ["mrt-row-actions"],
      },
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    muiPaginationProps: {
      color: "secondary",
      rowsPerPageOptions: [5, 10, 20],
      shape: "rounded",
      variant: "outlined",
    },
    renderRowActionMenuItems: ({ closeMenu, row }) => [
      selectedProductNavigation === "active_product" && (
        <MenuItem
          key={0}
          onClick={() => {
            handleOpenEditProduct(row.original);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit Product
        </MenuItem>
      ),
      selectedProductNavigation === "active_product" && (
        <MenuItem
          key={1}
          onClick={() => {
            closeMenu();
            handleOpenDeleteProduct(row.original);
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete Product
        </MenuItem>
      ),
      selectedProductNavigation === "disabled_product" && (
        <MenuItem
          key={1}
          onClick={() => {
            closeMenu();
            handleOpenEnableProduct(row.original);
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Enable Product
        </MenuItem>
      ),
    ],
  });

  return (
    <Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px 10px 20px",
        }}
      >
        <Typography variant="h4" sx={{ color: "#004680", fontWeight: "bold" }}>
          {"Product Management"}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0px 20px 10px 20px",
        }}
      >
        <Grid sx={{ justifyContent: "center", padding: "5px" }}>
          <Button
            onClick={() => {
              handleOpenCreateProduct();
            }}
            variant="outlined"
            sx={{
              color: "white !important",
              fontWeight: "bold !important",
              padding: "0px 20px 0px 20px !important",
              backgroundColor: "#38B54A !important",
            }}
          >
            Add Subscription
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          display: "flex",
          padding: "0px 20px 20px 20px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#4C4C4C !important",
            borderTop: "5px solid white !important",
            height: "40px",
            paddingLeft: "10px",
          }}
        >
          <Button
            onClick={() => {
              handleProductNavigation("active_product");
            }}
            sx={{
              color:
                selectedProductNavigation === "active_product"
                  ? "#38B54A !important"
                  : "white !important",
              padding: "0px 20px 0px 20px !important",
            }}
          >
            Active Products
          </Button>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ color: "white", backgroundColor: "white" }}
          />
          <Button
            onClick={() => {
              handleProductNavigation("disabled_product");
            }}
            sx={{
              color:
                selectedProductNavigation === "disabled_product"
                  ? "#38B54A !important"
                  : "white !important",
              padding: "0px 20px 0px 20px !important",
            }}
          >
            Disabled Products
          </Button>
        </Grid>
        <Grid container item xs={12} sx={{ padding: "20px 0px 20px 0px" }}>
          <TableTemplate tableData={productTable} />
        </Grid>
      </Grid>
      <CreateProductDialog
        open={openCreateProductDialog}
        handleCreateProductClose={handleCloseCreateProduct}
      />
      <EditProductDialog
        open={openEditProductDialog}
        handleEditProductClose={handleCloseEditProduct}
        editProductData={editProductRowData}
      />
      <DeleteProductDialog
        open={openDeleteProductDialog}
        handleDeleteProductClose={handleCloseDeleteProduct}
        deleteProductData={editProductRowData}
      />
      <EnableProductDialog
        open={openEnableProductDialog}
        handleEnableProductClose={handleCloseEnableProduct}
        enableProductData={editProductRowData}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={10000}
        open={openSnackbar}
        message={snackbarMessage}
        key={"topcenter"}
        onClose={() => setOpenSnackbar(false)}
      />
    </Grid>
  );
};
export default ProductManagement;
