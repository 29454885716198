import { createSlice } from "@reduxjs/toolkit";
import {
  getClientsInfo,
  getClientsPrograms,
  getClientsSalesRep,
  getBranchesDepartments,
  getLastUpdated,
  getActiveAccount12,
  getTransaction12,
  getRevenue12,
  getGrowthScore,
  getRetention,
  getAtRisk,
  getGoogleReviews,
  getNpsScore,
  getNpsSurvey,
  getIndustries,
  getPurchase,
  getRetentionLeads,
  getProspectVerified,
  getEmailReports,
  getGrowthRate,
  getGrowthRateDetail,
  getGrowthRateBenchmark,
  getForecast,
  getForecastDetail,
  getAtriskDetail,
  getWgs,
  getWgsDetail,
  getGgdRating,
  getGgdRatingDetail,
  getNetPromoter,
  getCustomerSurvey,
  getRetentionRate,
  getLastPurchaseCustomerLeads,
  getLastPurchaseDetail,
  getProspectLeadsReports,
  getProspectLeadsReportsDetail,
  getGainLostAccounts,
  getIndustryByRevenue,
  getIndustryByRevenueDetail,
  getPurchaseDistribution,
  getDistributionTransactionRevenue,
  getCustomerSurveyDetail,
  getProductAnalysisDetail,
  getRankByEmail,
  getRankByRetention,
  getRankByStableAccount,
  getRankByWgs,
  getRankByWgsDetails,
  getMarketByCustomer,
  getMarketOverview,
  getProductByCustomerCount,
  getProductByRevenue,
  getForecastRevenuePlan,
  getActualMargin,
  getBranchTransaction,
  getTrainingCourse,
  getTrainingModuleSummary,
  getTrainingModule,
} from "../../thunks/portalManagementThunk";
import { selectInfo } from "../../../types/formTypes";

interface PortalManagementState {
  portalLoading: Boolean;
  lastUpdateLoading: Boolean;

  dashboardLoading: Boolean;
  growthForecastLoading: Boolean;
  transactionsLoading: Boolean;
  salesMarketingLoading: Boolean;

  activeAccountLoading: Boolean;
  forecastLoading: Boolean;
  transactionLoading: Boolean;
  revenueLoading: Boolean;
  growthScoreLoading: Boolean;
  atRiskLoading: Boolean;
  npsScoreLoading: Boolean;
  retentionLoading: Boolean;
  lastPurchaseCustomerLeadsLoading: Boolean;
  emailReportsLoading: Boolean;
  growthRateLoading: Boolean;
  benchMarkLoading: Boolean;
  netPromoterLoading: Boolean;
  customerSurveyLoading: Boolean;
  retentionRateLoading: Boolean;
  industryByRevenueLoading: Boolean;
  purchaseDistributionLoading: Boolean;
  branchTransactionLoading: Boolean;
  distributionTransactionRevenueLoading: Boolean;
  forecastRevenuePlanLoading: Boolean;
  prospectLeadsReportsLoading: Boolean;
  gainLostAccountsLoading: Boolean;
  marketByCustomerLoading: Boolean;
  productByRevenueLoading: Boolean;
  productByCustomerCountLoading: Boolean;
  rankByStableAccountLoading: Boolean;
  rankByEmailLoading: Boolean;
  trainingCourseLoading: Boolean;
  trainingModuleSummaryLoading: Boolean;
  trainingModuleLoading: Boolean;
  rankByWgsDetailsLoading: Boolean;

  error: any;
  response: any;
  success: Boolean;
  mainView: Boolean;
  portalView: Boolean;
  accountType: Object[];

  portalClients: Object[];
  portalClientsLoading: boolean;

  portalClientPrograms: Object[];
  portalClientProgramsLoading: boolean;

  portalAccountTypes: Object[];

  portalSalesRep: Object[];
  portalSalesRepLoading: boolean;

  selectedClient: any;
  selectedLastUpdated: any;
  selectedProgram: any;
  selectedAccountType: any;
  selectedSaleRep: any;

  selectedBranch: selectInfo;
  selectedDepartment: selectInfo;

  branches: string[];
  departments: string[];

  forecastData?: any;
  revinvData?: any;
  purchaseTagRawData?: any;
  purchaseFreqRawData?: any;
  wsrData?: any;
  atRiskData?: any;
  saleLeadRawData?: any;
  leadRawData?: any;
  lostRawData?: any;
  gainRawData?: any;
  benchRawData?: any;
  sseRawData?: any;
  sseSumRawData?: any;
  dealerRawData?: any;
  benchWSGRawData?: any;
  benchBranches?: string[];
  wsr12RawData?: any;
  wsr36RawData?: any;
  surveyRawData?: any;
  branchTransactionsRawData?: any;
  productDistributionRawData?: any;
  industryRawData?: any;
  industryAllRawData?: any;
  balanceRawData?: any;
  verifiedSunRawData?: any;
  formFillSumRawData?: any;
  gainLost6RawData?: any;
  gainLost12RawData?: any;
  gainLost36RawData?: any;
  marketCustRawData?: any;
  marketCustAllRawData?: any;
  prcustRawData?: any;
  prrevRawData?: any;
  salstbRawData?: any;
  salemRawData?: any;
  trainingCourseRawData?: any;
  trainingModuleSummaryRawData?: any;
  trainingModuleRawData?: any;
  rankByWgsDetailsRawData?: any;
  prospectleadDetailRawData?: any;
}

const initialState: PortalManagementState = {
  portalLoading: false,
  lastUpdateLoading: false,
  dashboardLoading: false,
  growthForecastLoading: false,
  transactionsLoading: false,
  salesMarketingLoading: false,

  activeAccountLoading: false,
  forecastLoading: false,
  transactionLoading: false,
  revenueLoading: false,
  growthScoreLoading: false,
  atRiskLoading: false,
  npsScoreLoading: false,
  retentionLoading: false,
  lastPurchaseCustomerLeadsLoading: false,
  emailReportsLoading: false,
  growthRateLoading: false,
  benchMarkLoading: false,
  netPromoterLoading: false,
  customerSurveyLoading: false,
  retentionRateLoading: false,
  industryByRevenueLoading: false,
  purchaseDistributionLoading: false,
  branchTransactionLoading: false,
  distributionTransactionRevenueLoading: false,
  forecastRevenuePlanLoading: false,
  prospectLeadsReportsLoading: false,
  gainLostAccountsLoading: false,
  marketByCustomerLoading: false,
  productByRevenueLoading: false,
  productByCustomerCountLoading: false,
  rankByStableAccountLoading: false,
  rankByEmailLoading: false,
  trainingCourseLoading: false,
  trainingModuleSummaryLoading: false,
  trainingModuleLoading: false,
  rankByWgsDetailsLoading: false,

  error: null,
  response: null,
  success: false,
  mainView: true,
  portalView: true,
  accountType: [],
  portalClients: [],
  portalClientsLoading: false,
  portalClientProgramsLoading: false,
  portalSalesRepLoading: false,
  selectedClient: null,
  selectedLastUpdated: null,
  selectedProgram: null,
  selectedAccountType: null,
  selectedSaleRep: null,
  portalClientPrograms: [],
  portalAccountTypes: [],
  portalSalesRep: [],
  selectedBranch: { label: "Overall", value: "Overall" },
  selectedDepartment: { label: "Overall", value: "Overall" },

  branches: [],
  departments: [],
  forecastData: null,
  revinvData: null,
  purchaseTagRawData: null,
  purchaseFreqRawData: null,
  wsrData: null,
  atRiskData: null,
  saleLeadRawData: null,
  leadRawData: null,
  lostRawData: null,
  gainRawData: null,
  benchRawData: null,
  sseRawData: null,
  sseSumRawData: null,
  dealerRawData: null,
  benchWSGRawData: null,
  benchBranches: [],
  wsr12RawData: null,
  wsr36RawData: null,
  surveyRawData: null,
  branchTransactionsRawData: null,
  productDistributionRawData: null,
  industryRawData: null,
  industryAllRawData: null,
  balanceRawData: null,
  verifiedSunRawData: null,
  formFillSumRawData: null,
  gainLost6RawData: null,
  gainLost12RawData: null,
  gainLost36RawData: null,
  marketCustRawData: null,
  marketCustAllRawData: null,
  prcustRawData: null,
  prrevRawData: null,
  salstbRawData: null,
  salemRawData: null,
  trainingCourseRawData: null,
  trainingModuleSummaryRawData: null,
  trainingModuleRawData: null,
  rankByWgsDetailsRawData: null,
  prospectleadDetailRawData: null,
};

export const PortalManagementSlice = createSlice({
  name: "portalManagement",
  initialState,
  reducers: {
    portalManagementReset: (state) => {
      state.mainView = true;
      state.accountType = [];
      state.portalClients = [];
      state.portalAccountTypes = [];
      state.portalSalesRep = [];
      state.selectedClient = null;
      state.selectedLastUpdated = null;
      state.selectedProgram = null;
      state.selectedAccountType = null;
      state.selectedSaleRep = null;
      state.portalClientPrograms = [];
      state.selectedBranch = { label: "Overall", value: "Overall" };
      state.selectedDepartment = { label: "Overall", value: "Overall" };

      state.forecastData = null;
      state.revinvData = null;
      state.purchaseTagRawData = null;
      state.purchaseFreqRawData = null;
      state.wsrData = null;
      state.atRiskData = null;
      state.saleLeadRawData = null;
      state.leadRawData = null;
      state.lostRawData = null;
      state.gainRawData = null;
      state.benchRawData = null;
      state.sseRawData = null;
      state.sseSumRawData = null;
      state.dealerRawData = null;
      state.benchWSGRawData = null;
      state.benchBranches = [];
      state.wsr12RawData = null;
      state.wsr36RawData = null;
      state.surveyRawData = null;
      state.branchTransactionsRawData = null;
      state.productDistributionRawData = null;

      state.portalClientsLoading = false;
      state.portalClientProgramsLoading = false;
      state.portalSalesRepLoading = false;

      state.dashboardLoading = false;
      state.growthForecastLoading = false;
      state.transactionsLoading = false;
      state.salesMarketingLoading = false;

      state.industryRawData = null;
      state.industryAllRawData = null;
      state.balanceRawData = null;
      state.verifiedSunRawData = null;
      state.formFillSumRawData = null;
      state.gainLost6RawData = null;
      state.gainLost12RawData = null;
      state.gainLost36RawData = null;
      state.marketCustRawData = null;
      state.marketCustAllRawData = null;
      state.prcustRawData = null;
      state.prrevRawData = null;
      state.salstbRawData = null;
      state.salemRawData = null;
      state.salstbRawData = null;
      state.trainingCourseRawData = null;
      state.trainingModuleSummaryRawData = null;
      state.trainingModuleRawData = null;
      state.rankByWgsDetailsRawData = null;
      state.prospectleadDetailRawData = null;

      state.activeAccountLoading = false;
      state.forecastLoading = false;
      state.transactionLoading = false;
      state.revenueLoading = false;
      state.growthScoreLoading = false;
      state.atRiskLoading = false;
      state.npsScoreLoading = false;
      state.retentionLoading = false;
      state.lastPurchaseCustomerLeadsLoading = false;
      state.emailReportsLoading = false;
      state.growthRateLoading = false;
      state.benchMarkLoading = false;
      state.netPromoterLoading = false;
      state.customerSurveyLoading = false;
      state.retentionRateLoading = false;
      state.industryByRevenueLoading = false;
      state.purchaseDistributionLoading = false;
      state.branchTransactionLoading = false;
      state.distributionTransactionRevenueLoading = false;
      state.forecastRevenuePlanLoading = false;
      state.prospectLeadsReportsLoading = false;
      state.gainLostAccountsLoading = false;
      state.marketByCustomerLoading = false;
      state.productByRevenueLoading = false;
      state.productByCustomerCountLoading = false;
      state.rankByStableAccountLoading = false;
      state.rankByEmailLoading = false;
      state.trainingCourseLoading = false;
      state.trainingModuleSummaryLoading = false;
      state.trainingModuleLoading = false;
      state.rankByWgsDetailsLoading = false;
    },
    portalManagementSearchReset: (state) => {
      state.mainView = true;
      state.selectedBranch = { label: "Overall", value: "Overall" };
      state.selectedDepartment = { label: "Overall", value: "Overall" };

      state.forecastData = null;
      state.revinvData = null;
      state.purchaseTagRawData = null;
      state.purchaseFreqRawData = null;
      state.wsrData = null;
      state.atRiskData = null;
      state.saleLeadRawData = null;
      state.leadRawData = null;
      state.lostRawData = null;
      state.gainRawData = null;
      state.benchRawData = null;
      state.sseRawData = null;
      state.sseSumRawData = null;
      state.dealerRawData = null;
      state.benchWSGRawData = null;
      state.benchBranches = [];
      state.wsr12RawData = null;
      state.wsr36RawData = null;
      state.surveyRawData = null;
      state.branchTransactionsRawData = null;
      state.productDistributionRawData = null;

      state.portalClientsLoading = false;
      state.portalClientProgramsLoading = false;
      state.portalSalesRepLoading = false;

      state.dashboardLoading = false;
      state.growthForecastLoading = false;
      state.transactionsLoading = false;
      state.salesMarketingLoading = false;
      state.industryRawData = null;
      state.industryAllRawData = null;
      state.balanceRawData = null;
      state.verifiedSunRawData = null;
      state.formFillSumRawData = null;
      state.gainLost6RawData = null;
      state.gainLost12RawData = null;
      state.gainLost36RawData = null;
      state.marketCustRawData = null;
      state.marketCustAllRawData = null;
      state.prcustRawData = null;
      state.prrevRawData = null;
      state.salstbRawData = null;
      state.salemRawData = null;
      state.trainingCourseRawData = null;
      state.trainingModuleSummaryRawData = null;
      state.trainingModuleRawData = null;
      state.rankByWgsDetailsRawData = null;
      state.prospectleadDetailRawData = null;
    },
    updateMainView: (state) => {
      state.mainView = !state.mainView;
    },
    updatePortalView: (state) => {
      state.portalView = !state.portalView;
    },
    setPortalAccountTypes: (state, acyion) => {
      state.portalAccountTypes = acyion.payload;
    },
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
      state.portalClientPrograms = [];
      state.portalAccountTypes = [];
      state.portalSalesRep = [];
      state.selectedProgram = null;
      state.selectedAccountType = null;
      state.selectedSaleRep = null;
    },
    setSelectedClientOther: (state, action) => {
      state.selectedClient = action.payload;
    },
    setSelectedProgram: (state, action) => {
      state.selectedProgram = action.payload;
      state.portalAccountTypes = [];
      state.portalSalesRep = [];
      state.selectedAccountType = null;
      state.selectedSaleRep = null;
    },
    setSelectedAccountType: (state, action) => {
      state.selectedAccountType = action.payload;
      state.portalSalesRep = [];
      state.selectedSaleRep = null;
    },
    setSelectedSaleRep: (state, action) => {
      state.selectedSaleRep = action.payload;
    },
    setSelectedBranch: (state, action) => {
      state.selectedBranch = action.payload
        ? action.payload
        : { label: "Overall", value: "Overall" };
    },
    setSelectedDepartment: (state, action) => {
      state.selectedDepartment = action.payload
        ? action.payload
        : { label: "Overall", value: "Overall" };
    },
  },
  extraReducers(builder) {
    builder.addCase(getClientsInfo.pending, (state) => {
      state.portalClientsLoading = true;
      state.error = null;
    });
    builder.addCase(getClientsInfo.fulfilled, (state, action) => {
      state.portalClientsLoading = false;
      state.accountType = action.payload.account_type;
      state.portalClients = action.payload.client_id_name;
    });
    builder.addCase(getClientsInfo.rejected, (state, action) => {
      state.portalClientsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getClientsPrograms.pending, (state) => {
      state.portalClientProgramsLoading = true;
      state.error = null;
    });
    builder.addCase(getClientsPrograms.fulfilled, (state, action) => {
      state.portalClientProgramsLoading = false;
      state.portalClientPrograms = action.payload.programs;
    });
    builder.addCase(getClientsPrograms.rejected, (state, action) => {
      state.portalClientProgramsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getClientsSalesRep.pending, (state) => {
      state.portalSalesRepLoading = true;
      state.error = null;
    });
    builder.addCase(getClientsSalesRep.fulfilled, (state, action) => {
      state.portalSalesRepLoading = false;
      state.portalSalesRep = action.payload.salesmen_list.sort(
        (a: any, b: any) => {
          if (a.SALESMAN < b.SALESMAN) {
            return -1;
          }
        }
      );
    });
    builder.addCase(getClientsSalesRep.rejected, (state, action) => {
      state.portalSalesRepLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getBranchesDepartments.pending, (state) => {
      state.portalLoading = true;
      state.error = null;
    });
    builder.addCase(getBranchesDepartments.fulfilled, (state, action) => {
      state.portalLoading = false;
      state.branches = action.payload.all_branch
        .sort()
        .map((branch: string) => {
          return { value: branch, label: branch };
        });
      state.departments = action.payload.all_department
        .sort()
        .map((branch: string) => {
          return { value: branch, label: branch };
        });
    });
    builder.addCase(getBranchesDepartments.rejected, (state, action) => {
      state.portalLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getLastUpdated.pending, (state) => {
      state.lastUpdateLoading = true;
      state.error = null;
    });
    builder.addCase(getLastUpdated.fulfilled, (state, action) => {
      state.lastUpdateLoading = false;
      state.selectedLastUpdated = action.payload.last_updated;
    });
    builder.addCase(getLastUpdated.rejected, (state, action) => {
      state.lastUpdateLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getActiveAccount12.pending, (state) => {
      state.activeAccountLoading = true;
      state.error = null;
    });
    builder.addCase(getActiveAccount12.fulfilled, (state, action) => {
      state.activeAccountLoading = false;
      state.forecastData = {
        ...state.forecastData,
        ...action.payload.forecast_dic,
      };
      state.revinvData = { ...state.revinvData, ...action.payload.revinv_data };
    });
    builder.addCase(getActiveAccount12.rejected, (state, action) => {
      state.activeAccountLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getForecast.pending, (state) => {
      state.forecastLoading = true;
      state.error = null;
    });
    builder.addCase(getForecast.fulfilled, (state, action) => {
      state.forecastLoading = false;
      state.forecastData = {
        ...state.forecastData,
        ...action.payload.forecast_dic,
      };
      state.revinvData = { ...state.revinvData, ...action.payload.revinv_data };
    });
    builder.addCase(getForecast.rejected, (state, action) => {
      state.forecastLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getForecastDetail.pending, (state) => {
      state.forecastLoading = true;
      state.error = null;
    });
    builder.addCase(getForecastDetail.fulfilled, (state, action) => {
      state.forecastLoading = false;
      state.forecastData = {
        ...state.forecastData,
        ...action.payload.forecast_dic,
      };
      state.revinvData = { ...state.revinvData, ...action.payload.revinv_data };
    });
    builder.addCase(getForecastDetail.rejected, (state, action) => {
      state.forecastLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getTransaction12.pending, (state) => {
      state.transactionLoading = true;
      state.growthForecastLoading = true;
      state.error = null;
    });
    builder.addCase(getTransaction12.fulfilled, (state, action) => {
      state.transactionLoading = false;
      state.forecastData = {
        ...state.forecastData,
        ...action.payload.forecast_dic,
      };
      state.revinvData = { ...state.revinvData, ...action.payload.revinv_data };
    });
    builder.addCase(getTransaction12.rejected, (state, action) => {
      state.transactionLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getRevenue12.pending, (state) => {
      state.revenueLoading = true;
      state.growthForecastLoading = true;
      state.error = null;
    });
    builder.addCase(getRevenue12.fulfilled, (state, action) => {
      state.revenueLoading = false;
      state.forecastData = {
        ...state.forecastData,
        ...action.payload.forecast_dic,
      };
      state.revinvData = { ...state.revinvData, ...action.payload.revinv_data };
    });
    builder.addCase(getRevenue12.rejected, (state, action) => {
      state.revenueLoading = false;
      state.growthForecastLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getGrowthScore.pending, (state) => {
      state.growthScoreLoading = true;
      state.error = null;
    });
    builder.addCase(getGrowthScore.fulfilled, (state, action) => {
      state.growthScoreLoading = false;
      state.portalSalesRep = action.payload;
    });
    builder.addCase(getGrowthScore.rejected, (state, action) => {
      state.growthScoreLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getAtriskDetail.pending, (state) => {
      state.atRiskLoading = true;
      state.error = null;
    });
    builder.addCase(getAtriskDetail.fulfilled, (state, action) => {
      state.atRiskLoading = false;
      state.atRiskData = action.payload.atrisk_data;
    });
    builder.addCase(getAtriskDetail.rejected, (state, action) => {
      state.atRiskLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getNpsScore.pending, (state) => {
      state.npsScoreLoading = true;
      state.growthForecastLoading = true;
      state.error = null;
    });
    builder.addCase(getNpsScore.fulfilled, (state, action) => {
      state.npsScoreLoading = false;
      state.growthForecastLoading = false;
      state.wsr12RawData = {
        ...state.wsr12RawData,
        ...action.payload.wsr_12m_dic,
      };
    });
    builder.addCase(getNpsScore.rejected, (state, action) => {
      state.npsScoreLoading = false;
      state.growthForecastLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getRetentionLeads.pending, (state) => {
      state.retentionLoading = true;
      state.error = null;
    });
    builder.addCase(getRetentionLeads.fulfilled, (state, action) => {
      state.retentionLoading = false;
      state.leadRawData = action.payload.lead_dic;
      state.lostRawData = action.payload.lost_dic;
      state.gainRawData = action.payload.gain_dic;
    });
    builder.addCase(getRetentionLeads.rejected, (state, action) => {
      state.retentionLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getLastPurchaseCustomerLeads.pending, (state) => {
      state.lastPurchaseCustomerLeadsLoading = true;
      state.error = null;
    });
    builder.addCase(getLastPurchaseCustomerLeads.fulfilled, (state, action) => {
      state.lastPurchaseCustomerLeadsLoading = false;
      state.leadRawData = { ...state.leadRawData, ...action.payload.lead_dic };
      state.lostRawData = { ...state.lostRawData, ...action.payload.lost_dic };
      state.gainRawData = { ...state.gainRawData, ...action.payload.gain_dic };
    });
    builder.addCase(getLastPurchaseCustomerLeads.rejected, (state, action) => {
      state.lastPurchaseCustomerLeadsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getLastPurchaseDetail.pending, (state) => {
      state.lastPurchaseCustomerLeadsLoading = true;
      state.error = null;
    });
    builder.addCase(getLastPurchaseDetail.fulfilled, (state, action) => {
      state.lastPurchaseCustomerLeadsLoading = false;
      state.saleLeadRawData = action.payload;
    });
    builder.addCase(getLastPurchaseDetail.rejected, (state, action) => {
      state.lastPurchaseCustomerLeadsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getEmailReports.pending, (state) => {
      state.emailReportsLoading = true;
      state.error = null;
    });
    builder.addCase(getEmailReports.fulfilled, (state, action) => {
      state.emailReportsLoading = false;
      state.benchRawData = action.payload.bench_dic;
      state.sseRawData = action.payload.ss_email_dic;
      state.sseSumRawData = action.payload.ss_email_sum;
    });
    builder.addCase(getEmailReports.rejected, (state, action) => {
      state.emailReportsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getGrowthRate.pending, (state) => {
      state.growthRateLoading = true;
      state.error = null;
    });
    builder.addCase(getGrowthRate.fulfilled, (state, action) => {
      state.growthRateLoading = false;
      state.revinvData = { ...state.revinvData, ...action.payload.revinv_data };
    });
    builder.addCase(getGrowthRate.rejected, (state, action) => {
      state.growthRateLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getGrowthRateBenchmark.pending, (state) => {
      state.benchMarkLoading = true;
      state.error = null;
    });
    builder.addCase(getGrowthRateBenchmark.fulfilled, (state, action) => {
      state.benchMarkLoading = false;
      state.benchRawData = {
        ...state.benchRawData,
        ...action.payload.bench_dic,
      };
      state.dealerRawData = action.payload.deal_dic;
      state.benchWSGRawData = action.payload.bench_wgs;
      state.benchBranches = action.payload.all_branch;
    });
    builder.addCase(getGrowthRateBenchmark.rejected, (state, action) => {
      state.benchMarkLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getNetPromoter.pending, (state) => {
      state.netPromoterLoading = true;
      state.error = null;
    });
    builder.addCase(getNetPromoter.fulfilled, (state, action) => {
      state.netPromoterLoading = false;
      state.wsr12RawData = {
        ...state.wsr12RawData,
        ...action.payload.wsr_12m_dic,
      };
      state.surveyRawData = action.payload.survey_data;
    });
    builder.addCase(getNetPromoter.rejected, (state, action) => {
      state.netPromoterLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getCustomerSurvey.pending, (state) => {
      state.customerSurveyLoading = true;
      state.error = null;
    });
    builder.addCase(getCustomerSurvey.fulfilled, (state, action) => {
      state.customerSurveyLoading = false;
      state.wsr12RawData = {
        ...state.wsr12RawData,
        ...action.payload.wsr_12m_dic,
      };
    });
    builder.addCase(getCustomerSurvey.rejected, (state, action) => {
      state.customerSurveyLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getCustomerSurveyDetail.pending, (state) => {
      state.customerSurveyLoading = false;
      state.error = null;
    });
    builder.addCase(getCustomerSurveyDetail.fulfilled, (state, action) => {
      state.customerSurveyLoading = false;
      state.wsr36RawData = action.payload.wsr_36m_dic;
    });
    builder.addCase(getCustomerSurveyDetail.rejected, (state, action) => {
      state.customerSurveyLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getRetentionRate.pending, (state) => {
      state.retentionRateLoading = true;
      state.error = null;
    });
    builder.addCase(getRetentionRate.fulfilled, (state, action) => {
      state.retentionRateLoading = false;
      state.revinvData = { ...state.revinvData, ...action.payload.revinv_data };
    });
    builder.addCase(getRetentionRate.rejected, (state, action) => {
      state.retentionRateLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getIndustryByRevenueDetail.pending, (state) => {
      state.industryByRevenueLoading = true;
      state.error = null;
    });
    builder.addCase(getIndustryByRevenueDetail.fulfilled, (state, action) => {
      state.industryByRevenueLoading = false;
      state.industryRawData = action.payload.industry_distribution;
      state.industryAllRawData = action.payload.industry_all;
    });
    builder.addCase(getIndustryByRevenueDetail.rejected, (state, action) => {
      state.industryByRevenueLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getPurchaseDistribution.pending, (state) => {
      state.purchaseDistributionLoading = true;
      state.error = null;
    });
    builder.addCase(getPurchaseDistribution.fulfilled, (state, action) => {
      state.purchaseDistributionLoading = false;
      state.purchaseTagRawData = action.payload.last_purchase_tag;
      state.purchaseFreqRawData = action.payload.purchase_frequency;
    });
    builder.addCase(getPurchaseDistribution.rejected, (state, action) => {
      state.purchaseDistributionLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getBranchTransaction.pending, (state) => {
      state.branchTransactionLoading = true;
      state.error = null;
    });
    builder.addCase(getBranchTransaction.fulfilled, (state, action) => {
      state.branchTransactionLoading = false;
      state.branchTransactionsRawData = action.payload.branch_data;
    });
    builder.addCase(getBranchTransaction.rejected, (state, action) => {
      state.branchTransactionLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getDistributionTransactionRevenue.pending, (state) => {
      state.distributionTransactionRevenueLoading = true;
      state.error = null;
    });
    builder.addCase(
      getDistributionTransactionRevenue.fulfilled,
      (state, action) => {
        state.distributionTransactionRevenueLoading = false;
        state.productDistributionRawData = action.payload.product_distribution;
      }
    );
    builder.addCase(
      getDistributionTransactionRevenue.rejected,
      (state, action) => {
        state.distributionTransactionRevenueLoading = false;
        state.error = action.payload;
      }
    );

    builder.addCase(getForecastRevenuePlan.pending, (state) => {
      state.forecastRevenuePlanLoading = true;
      state.error = null;
    });
    builder.addCase(getForecastRevenuePlan.fulfilled, (state, action) => {
      state.forecastRevenuePlanLoading = false;
      state.balanceRawData = action.payload.plan_margin_dict;
    });
    builder.addCase(getForecastRevenuePlan.rejected, (state, action) => {
      state.forecastRevenuePlanLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getProspectLeadsReports.pending, (state) => {
      state.prospectLeadsReportsLoading = true;
      state.error = null;
    });
    builder.addCase(getProspectLeadsReports.fulfilled, (state, action) => {
      state.prospectLeadsReportsLoading = false;
      state.verifiedSunRawData = action.payload.verified_sum;
      state.formFillSumRawData = action.payload.form_fill_sum;
    });
    builder.addCase(getProspectLeadsReports.rejected, (state, action) => {
      state.prospectLeadsReportsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getProspectLeadsReportsDetail.pending, (state) => {
      state.prospectLeadsReportsLoading = true;
      state.error = null;
    });
    builder.addCase(
      getProspectLeadsReportsDetail.fulfilled,
      (state, action) => {
        state.prospectLeadsReportsLoading = false;
        state.prospectleadDetailRawData = action.payload;
      }
    );
    builder.addCase(getProspectLeadsReportsDetail.rejected, (state, action) => {
      state.prospectLeadsReportsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getGainLostAccounts.pending, (state) => {
      state.gainLostAccountsLoading = true;
      state.error = null;
    });
    builder.addCase(getGainLostAccounts.fulfilled, (state, action) => {
      state.gainLostAccountsLoading = false;
      state.revinvData = { ...state.revinvData, ...action.payload.revinv_data };
      state.gainLost6RawData = action.payload.gl6m_dic;
      state.gainLost12RawData = action.payload.gl12m_dic;
      state.gainLost36RawData = action.payload.gl36m_dic;
    });
    builder.addCase(getGainLostAccounts.rejected, (state, action) => {
      state.gainLostAccountsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getMarketByCustomer.pending, (state) => {
      state.marketByCustomerLoading = true;
      state.error = null;
    });
    builder.addCase(getMarketByCustomer.fulfilled, (state, action) => {
      state.marketByCustomerLoading = false;
      state.marketCustRawData = action.payload.market_customer;
    });
    builder.addCase(getMarketByCustomer.rejected, (state, action) => {
      state.marketByCustomerLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getMarketOverview.pending, (state) => {
      state.marketByCustomerLoading = true;
      state.error = null;
    });
    builder.addCase(getMarketOverview.fulfilled, (state, action) => {
      state.marketByCustomerLoading = false;
      state.marketCustAllRawData = action.payload.market_overall;
    });
    builder.addCase(getMarketOverview.rejected, (state, action) => {
      state.marketByCustomerLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getProductByRevenue.pending, (state) => {
      state.productByRevenueLoading = true;
      state.error = null;
    });
    builder.addCase(getProductByRevenue.fulfilled, (state, action) => {
      state.productByRevenueLoading = false;
      state.prrevRawData = action.payload.rev_pr_dic;
    });
    builder.addCase(getProductByRevenue.rejected, (state, action) => {
      state.productByRevenueLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getProductByCustomerCount.pending, (state) => {
      state.productByCustomerCountLoading = true;
      state.error = null;
    });
    builder.addCase(getProductByCustomerCount.fulfilled, (state, action) => {
      state.productByCustomerCountLoading = false;
      state.prcustRawData = action.payload.cust_pr_dic;
    });
    builder.addCase(getProductByCustomerCount.rejected, (state, action) => {
      state.productByCustomerCountLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getRankByStableAccount.pending, (state) => {
      state.rankByStableAccountLoading = true;
      state.error = null;
    });
    builder.addCase(getRankByStableAccount.fulfilled, (state, action) => {
      state.rankByStableAccountLoading = false;
      state.salstbRawData = action.payload.stb_sal_rank;
    });
    builder.addCase(getRankByStableAccount.rejected, (state, action) => {
      state.rankByStableAccountLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getRankByEmail.pending, (state) => {
      state.rankByEmailLoading = true;
      state.error = null;
    });
    builder.addCase(getRankByEmail.fulfilled, (state, action) => {
      state.rankByEmailLoading = false;
      state.salemRawData = action.payload.em_sal_rank;
    });
    builder.addCase(getRankByEmail.rejected, (state, action) => {
      state.rankByEmailLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getTrainingCourse.pending, (state) => {
      state.trainingCourseLoading = true;
      state.error = null;
    });
    builder.addCase(getTrainingCourse.fulfilled, (state, action) => {
      state.trainingCourseLoading = false;
      state.trainingCourseRawData = action.payload;
    });
    builder.addCase(getTrainingCourse.rejected, (state, action) => {
      state.trainingCourseLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getTrainingModuleSummary.pending, (state) => {
      state.trainingModuleSummaryLoading = true;
      state.error = null;
    });
    builder.addCase(getTrainingModuleSummary.fulfilled, (state, action) => {
      state.trainingModuleSummaryLoading = false;
      state.trainingModuleSummaryRawData = action.payload;
    });
    builder.addCase(getTrainingModuleSummary.rejected, (state, action) => {
      state.trainingModuleSummaryLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getTrainingModule.pending, (state) => {
      state.trainingModuleLoading = true;
      state.error = null;
    });
    builder.addCase(getTrainingModule.fulfilled, (state, action) => {
      state.trainingModuleLoading = false;
      state.trainingModuleRawData = action.payload;
    });
    builder.addCase(getTrainingModule.rejected, (state, action) => {
      state.trainingModuleLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getRankByWgsDetails.pending, (state) => {
      state.rankByWgsDetailsLoading = true;
      state.error = null;
    });
    builder.addCase(getRankByWgsDetails.fulfilled, (state, action) => {
      state.rankByWgsDetailsLoading = false;
      state.rankByWgsDetailsRawData = action.payload;
    });
    builder.addCase(getRankByWgsDetails.rejected, (state, action) => {
      state.rankByWgsDetailsLoading = false;
      state.error = action.payload;
    });
  },
});

export const {
  updateMainView,
  updatePortalView,
  setSelectedClient,
  setSelectedProgram,
  setSelectedAccountType,
  setSelectedSaleRep,
  portalManagementReset,
  setPortalAccountTypes,
  setSelectedBranch,
  setSelectedDepartment,
  portalManagementSearchReset,
  setSelectedClientOther,
} = PortalManagementSlice.actions;

export const portalManagementReducer = PortalManagementSlice.reducer;
