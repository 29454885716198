import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  setSelectedClient,
  setSelectedProgram,
  updateMainView,
  setPortalAccountTypes,
  setSelectedAccountType,
  setSelectedSaleRep,
  portalManagementSearchReset,
} from "../../store/features/portalManagement/portalManagementSlice";
import {
  getClientsInfo,
  getClientsPrograms,
  getClientsSalesRep,
} from "../../store/thunks/portalManagementThunk";
import Spinner from "../reusableComponents/spinner/spinner";

const PortalManagement = () => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state: any) => state.authentication);
  const {
    accountType,
    portalClients,
    selectedClient,
    selectedProgram,
    selectedAccountType,
    selectedSaleRep,
    portalClientPrograms,
    portalAccountTypes,
    portalSalesRep,
    portalClientsLoading,
    portalClientProgramsLoading,
    portalSalesRepLoading,
    portalView,
  } = useAppSelector((state: any) => state.portalManagement);

  const [portalInfoError, setportalInfoError] = useState(false);
  const [selectedTab, setSelectedTab] = useState("portal");

  const [clientError, setClientError] = useState(false);
  const [clientErrorMessage, setClientErrorMessage] = useState("");

  const [programError, setProgramError] = useState(false);
  const [programErrorMessage, setProgramErrorMessage] = useState("");

  const [accountTypeError, setAccountTypeError] = useState(false);
  const [accountTypeErrorMessage, setAccountTypeErrorMessage] = useState("");

  const [saleRepError, setSaleRepError] = useState(false);
  const [saleRepErrorMessage, setSaleRepErrorMessage] = useState("");

  useEffect(() => {
    if (
      !selectedClient &&
      !selectedProgram &&
      !selectedSaleRep &&
      !selectedAccountType
    )
      dispatch(getClientsInfo({}))
        .then((result: any) => {
          if (
            userInfo?.role_index === 103 &&
            userInfo?.client_type_name === "Manager"
          ) {
            dispatch(
              getClientsPrograms({
                client_index: result.payload.client_id_name[0].client_index,
              })
            ).then(() => {});
          }
        })
        .then(() => {});
  }, []);

  const handlePortalSelectClient = (newValue: any) => {
    setClientError(false);
    setClientErrorMessage("");
    setProgramError(false);
    setProgramErrorMessage("");
    setAccountTypeError(false);
    setAccountTypeErrorMessage("");
    setSaleRepError(false);
    setSaleRepErrorMessage("");
    dispatch(setSelectedClient(newValue));
    dispatch(portalManagementSearchReset());
    if (newValue) {
      dispatch(
        getClientsPrograms({ client_index: newValue.client_index })
      ).then(() => {});
    }
  };

  const handlePortalSelectProgram = (newValue: any) => {
    setProgramError(false);
    setProgramErrorMessage("");
    setAccountTypeError(false);
    setAccountTypeErrorMessage("");
    setSaleRepError(false);
    setSaleRepErrorMessage("");

    dispatch(setSelectedProgram(newValue));
    let tempAccountType: any[] = [];
    if (newValue?.program_index === 100) {
      accountType.forEach((el: any) => {
        if (el.client_type_index === 101 || el.client_type_index === 100) {
          tempAccountType.push(el);
        }
      });
    } else if (newValue?.program_index === 101) {
      accountType.forEach((el: any) => {
        if (el.client_type_index === 101) {
          tempAccountType.push(el);
        }
      });
    } else if (newValue?.program_index === 102) {
      accountType.forEach((el: any) => {
        if (el.client_type_index === 103) {
          tempAccountType.push(el);
        }
      });
    } else if (newValue?.program_index === 103) {
      accountType.forEach((el: any) => {
        if (el.client_type_index === 101) {
          tempAccountType.push(el);
        }
      });
    }
    dispatch(setPortalAccountTypes(tempAccountType));
  };

  const handlePortalSelectAccountType = (newValue: any) => {
    setAccountTypeError(false);
    setAccountTypeErrorMessage("");
    setSaleRepError(false);
    setSaleRepErrorMessage("");

    if (newValue?.client_type_index === 100) {
      if (
        userInfo?.role_index === 103 &&
        userInfo?.client_type_name === "Manager"
      ) {
        dispatch(
          getClientsSalesRep({ customer_id: portalClients[0].customer_id })
        ).then(() => {});
      } else {
        dispatch(
          getClientsSalesRep({ customer_id: selectedClient.customer_id })
        ).then(() => {});
      }
    }
    dispatch(setSelectedAccountType(newValue));
  };

  const handlePortalSelectSaleRep = (newValue: any) => {
    setSaleRepError(false);
    setSaleRepErrorMessage("");
    dispatch(setSelectedSaleRep(newValue));
  };

  const submitSlelectDashboard = () => {
    if (
      userInfo?.role_index === 103 &&
      userInfo?.client_type_name === "Manager"
    ) {
      if (!selectedProgram) {
        setProgramError(true);
        setProgramErrorMessage("Please select a program");
        return;
      }
      if (!selectedAccountType) {
        setAccountTypeError(true);
        setAccountTypeErrorMessage("Please select an account type");
        return;
      } else {
        if (selectedAccountType.client_type_index === 100) {
          if (!selectedSaleRep) {
            setSaleRepError(true);
            setSaleRepErrorMessage("Please select a sale rep");
            return;
          }
        }
      }
    } else {
      if (!selectedClient) {
        setClientError(true);
        setClientErrorMessage("Please select a client");
        return;
      }
      if (!selectedProgram) {
        setProgramError(true);
        setProgramErrorMessage("Please select a program");
        return;
      }
      if (!selectedAccountType) {
        setAccountTypeError(true);
        setAccountTypeErrorMessage("Please select an account type");
        return;
      } else {
        if (selectedAccountType.client_type_index === 100) {
          if (!selectedSaleRep) {
            setSaleRepError(true);
            setSaleRepErrorMessage("Please select a sale rep");
            return;
          }
        }
      }
    }
    dispatch(updateMainView());
  };

  return (
    <Grid
      container
      sx={{
        border: "1px solid grey",
        backgroundColor: "white",
        borderRadius: "10px",
        margin: "20px",
      }}
    >
      <Spinner loading={portalClientsLoading} />
      <Spinner loading={portalClientProgramsLoading} />
      <Spinner loading={portalSalesRepLoading} />
      <Grid container sx={{ display: "flex", padding: "20px 0px 0px 20px" }}>
        <Typography variant="h6" sx={{ color: "#3B934A", fontWeight: "bold" }}>
          Select Dashboard
        </Typography>
      </Grid>
      <Grid container sx={{ padding: "10px 10% 10px 0px" }}>
        {userInfo.role_index !== 103 && (
          <Grid
            container
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "10px 0px 10px 0px",
            }}
          >
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                height: "100%",
                justifyContent: "flex-end",
                padding: "10px 10px 0px 0px",
              }}
            >
              <Typography variant="h5">Clients: </Typography>
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                id="clients"
                value={selectedClient}
                onChange={(_: SyntheticEvent, newValue: string | null) => {
                  handlePortalSelectClient(newValue);
                }}
                options={portalClients}
                getOptionLabel={(option) => option.customer_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Clients"
                    error={clientError}
                    helperText={clientErrorMessage}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            margin: "10px 0px 10px 0px",
          }}
        >
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              height: "100%",
              justifyContent: "flex-end",
              padding: "10px 10px 0px 0px",
            }}
          >
            <Typography variant="h5">Programs: </Typography>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              id="programs"
              value={selectedProgram}
              onChange={(_: SyntheticEvent, newValue: string | null) => {
                handlePortalSelectProgram(newValue);
              }}
              options={portalClientPrograms}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  placeholder="Programs"
                  error={programError}
                  helperText={programErrorMessage}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            margin: "10px 0px 10px 0px",
          }}
        >
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              height: "100%",
              justifyContent: "flex-end",
              padding: "10px 10px 0px 0px",
            }}
          >
            <Typography variant="h5">Account Type: </Typography>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              id="account_type"
              value={selectedAccountType}
              onChange={(_: SyntheticEvent, newValue: string | null) => {
                handlePortalSelectAccountType(newValue);
              }}
              options={portalAccountTypes}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  placeholder="Account Type"
                  error={accountTypeError}
                  helperText={accountTypeErrorMessage}
                />
              )}
            />
          </Grid>
        </Grid>
        {selectedAccountType &&
          selectedAccountType.client_type_index === 100 && (
            <Grid
              container
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                margin: "10px 0px 10px 0px",
              }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "flex-end",
                  padding: "10px 10px 0px 0px",
                }}
              >
                <Typography variant="h5">Sales Rep: </Typography>
              </Grid>

              <Grid item xs={8}>
                <Autocomplete
                  id="sales_rep"
                  value={selectedSaleRep}
                  onChange={(_: SyntheticEvent, newValue: string | null) => {
                    handlePortalSelectSaleRep(newValue);
                  }}
                  options={portalSalesRep}
                  getOptionLabel={(option) => option.SALESMAN}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Sales Rep"
                      error={saleRepError}
                      helperText={saleRepErrorMessage}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
      </Grid>
      <Grid
        container
        sx={{ padding: "20px 0px 20px 0px", justifyContent: "center" }}
      >
        <Button
          variant="contained"
          sx={{ width: "200px", backgroundColor: "#004680" }}
          onClick={() => submitSlelectDashboard()}
          type="submit"
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default PortalManagement;

// const validationSchema = Yup.object().shape({

//       isCompany: Yup.boolean(),
//       companyName: Yup.string().when('isCompany', {
//         is: true,
//         then: Yup.string().required('Field is required')
//       }),
//       companyAddress: Yup.string().when('isCompany', {
//         is: (isCompany) => true,//just an e.g. you can return a function
//         then: Yup.string().required('Field is required'),
//         otherwise: Yup.string()
//       }),
//       // test an unconventional type
//       logo: Yup.mixed()
//        .test('file_size', 'error: file size exceeds!', (files, context) => {
//          return filesIsValid(files) ? files[0].size <= 10000 : true
//     /** with Input file, target will be a FileList,
//     you can handle onChange at your form level
//     // [Please note]: Here finally I'm returning `true`,
//     // you can use context?.createError() but it will void .isNotReuired()
//     // meaning even if you keep this field optional your form will be still invalid
//     **/
//        })
//        .test('file_type', 'error msg: file type must match', (files, context) => {// test and return bool or contextError})
//     });
