import { createSlice } from "@reduxjs/toolkit";
import {
  getSystemData,
  addUser,
  getUsers,
  editUser,
  disableUser,
  enableUser,
  getClients,
  addClient,
  editClient,
  disableClient,
  enableClient,
  getRoles,
  getProducts,
  editProduct,
  deleteProduct,
  enableProduct,
  addProduct,
  getPrograms,
  getPlans,
  getClientPlan,
  getClientType,
} from "../../thunks/systemManagementThunk";

interface SystemManagementState {
  systemLoading: Boolean;
  getUserLoading: Boolean;
  getClientsLoading: Boolean;
  getRolesLoading: Boolean;
  getProductsLoading: Boolean;
  getProgramsLoading: Boolean;
  getPlansLoading: Boolean;
  getClientPlanLoading: Boolean;
  getClientTypeLoading: Boolean;
  addUserLoading: Boolean;
  addClientLoading: Boolean;
  addProductLoading: Boolean;

  updateUserTabe?: Boolean;

  error: any;
  response: any;
  success: Boolean;

  activeSubscriber?: any;
  analyticSalesRep?: any;
  emailReports?: any;
  allUsers?: any;
  allClients?: any;
  clientPlan?: any;
  clientType?: any;
  allPlans?: any;
  allProducts?: any;
  allPrograms?: any;
  allRoles?: any;
  allSalesMen?: any;
}

const initialState: SystemManagementState = {
  systemLoading: false,
  getUserLoading: false,
  getClientsLoading: false,
  getRolesLoading: false,
  getProductsLoading: false,
  getProgramsLoading: false,
  getPlansLoading: false,
  getClientPlanLoading: false,
  getClientTypeLoading: false,
  addUserLoading: false,
  addClientLoading: false,
  addProductLoading: false,

  error: null,
  response: null,
  success: false,

  activeSubscriber: null,
  analyticSalesRep: null,
  emailReports: null,
  allUsers: null,
  allClients: null,
  clientPlan: null,
  clientType: null,
  allPlans: null,
  allProducts: null,
  allPrograms: null,
  allRoles: null,
  allSalesMen: null,
};

export const systemManagementSlice = createSlice({
  name: "systemManagement",
  initialState,
  reducers: {
    systemManagementReset: (state) => {
      state.allUsers = null;
      state.activeSubscriber = null;
      state.analyticSalesRep = null;
      state.emailReports = null;
      state.allClients = null;
      state.clientPlan = null;
      state.clientType = null;
      state.allPlans = null;
      state.allProducts = null;
      state.allPrograms = null;
      state.allRoles = null;
      state.allSalesMen = null;
      state.systemLoading = false;
      state.error = null;
      state.response = null;

      state.getUserLoading = false;
      state.getClientsLoading = false;
      state.getRolesLoading = false;
      state.getProductsLoading = false;
      state.getProgramsLoading = false;
      state.getPlansLoading = false;
      state.getClientPlanLoading = false;
      state.getClientTypeLoading = false;
      state.addUserLoading = false;
      state.addClientLoading = false;
      state.addProductLoading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSystemData.pending, (state) => {
      state.systemLoading = true;
      state.error = null;
    });
    builder.addCase(getSystemData.fulfilled, (state, action) => {
      state.activeSubscriber = action.payload.active_subscriber;
      state.analyticSalesRep = action.payload.all_analytic_sale_rep;
      state.allSalesMen = action.payload.sales_men_list;
      state.systemLoading = false;
    });
    builder.addCase(getSystemData.rejected, (state, action) => {
      state.systemLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getUsers.pending, (state) => {
      state.getUserLoading = true;
      state.error = null;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload.all_users;
      state.getUserLoading = false;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.getUserLoading = false;
      state.error = action.payload;
    });

    builder.addCase(addUser.pending, (state) => {
      state.addUserLoading = true;
      state.error = null;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.response = action.payload;
      state.addUserLoading = false;
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.addUserLoading = false;
      state.error = action.payload;
    });

    builder.addCase(editUser.pending, (state) => {
      state.addUserLoading = true;
      state.error = null;
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.response = action.payload;
      state.addUserLoading = false;
    });
    builder.addCase(editUser.rejected, (state, action) => {
      state.addUserLoading = false;
      state.error = action.payload;
    });

    builder.addCase(disableUser.pending, (state) => {
      state.addUserLoading = true;
      state.error = null;
    });
    builder.addCase(disableUser.fulfilled, (state, action) => {
      state.response = action.payload;
      state.addUserLoading = false;
    });
    builder.addCase(disableUser.rejected, (state, action) => {
      state.addUserLoading = false;
      state.error = action.payload;
    });

    builder.addCase(enableUser.pending, (state) => {
      state.addUserLoading = true;
      state.error = null;
    });
    builder.addCase(enableUser.fulfilled, (state, action) => {
      state.response = action.payload;
      state.addUserLoading = false;
    });
    builder.addCase(enableUser.rejected, (state, action) => {
      state.addUserLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getClients.pending, (state) => {
      state.getClientsLoading = true;
      state.error = null;
    });
    builder.addCase(getClients.fulfilled, (state, action) => {
      state.allClients = action.payload.clients;
      state.getClientsLoading = false;
    });
    builder.addCase(getClients.rejected, (state, action) => {
      state.getClientsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(addClient.pending, (state) => {
      state.addClientLoading = true;
      state.error = null;
    });
    builder.addCase(addClient.fulfilled, (state, action) => {
      state.response = action.payload;
      state.addClientLoading = false;
    });
    builder.addCase(addClient.rejected, (state, action) => {
      state.addClientLoading = false;
      state.error = action.payload;
    });

    builder.addCase(editClient.pending, (state) => {
      state.addClientLoading = true;
      state.error = null;
    });
    builder.addCase(editClient.fulfilled, (state, action) => {
      state.response = action.payload;
      state.addClientLoading = false;
    });
    builder.addCase(editClient.rejected, (state, action) => {
      state.addClientLoading = false;
      state.error = action.payload;
    });

    builder.addCase(disableClient.pending, (state) => {
      state.addClientLoading = true;
      state.error = null;
    });
    builder.addCase(disableClient.fulfilled, (state, action) => {
      state.response = action.payload;
      state.addClientLoading = false;
    });
    builder.addCase(disableClient.rejected, (state, action) => {
      state.addClientLoading = false;
      state.error = action.payload;
    });

    builder.addCase(enableClient.pending, (state) => {
      state.addClientLoading = true;
      state.error = null;
    });
    builder.addCase(enableClient.fulfilled, (state, action) => {
      state.response = action.payload;
      state.addClientLoading = false;
    });
    builder.addCase(enableClient.rejected, (state, action) => {
      state.addClientLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getRoles.pending, (state) => {
      state.getRolesLoading = true;
      state.error = null;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.allRoles = action.payload.roles;
      state.getRolesLoading = false;
    });
    builder.addCase(getRoles.rejected, (state, action) => {
      state.getRolesLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getProducts.pending, (state) => {
      state.getProductsLoading = true;
      state.error = null;
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.allProducts = action.payload.products;
      state.getProductsLoading = false;
    });
    builder.addCase(getProducts.rejected, (state, action) => {
      state.getProductsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(addProduct.pending, (state) => {
      state.addProductLoading = true;
      state.error = null;
    });
    builder.addCase(addProduct.fulfilled, (state, action) => {
      state.response = action.payload;
      state.addProductLoading = false;
    });
    builder.addCase(addProduct.rejected, (state, action) => {
      state.addProductLoading = false;
      state.error = action.payload;
    });

    builder.addCase(editProduct.pending, (state) => {
      state.addProductLoading = true;
      state.error = null;
    });
    builder.addCase(editProduct.fulfilled, (state, action) => {
      state.response = action.payload;
      state.addProductLoading = false;
    });
    builder.addCase(editProduct.rejected, (state, action) => {
      state.addProductLoading = false;
      state.error = action.payload;
    });

    builder.addCase(deleteProduct.pending, (state) => {
      state.addProductLoading = true;
      state.error = null;
    });
    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      state.response = action.payload;
      state.addProductLoading = false;
    });
    builder.addCase(deleteProduct.rejected, (state, action) => {
      state.addProductLoading = false;
      state.error = action.payload;
    });

    builder.addCase(enableProduct.pending, (state) => {
      state.addProductLoading = true;
      state.error = null;
    });
    builder.addCase(enableProduct.fulfilled, (state, action) => {
      state.response = action.payload;
      state.addProductLoading = false;
    });
    builder.addCase(enableProduct.rejected, (state, action) => {
      state.addProductLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getPrograms.pending, (state) => {
      state.getProgramsLoading = true;
      state.error = null;
    });
    builder.addCase(getPrograms.fulfilled, (state, action) => {
      state.allPrograms = action.payload.programs;
      state.getProgramsLoading = false;
    });
    builder.addCase(getPrograms.rejected, (state, action) => {
      state.getProgramsLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getPlans.pending, (state) => {
      state.getPlansLoading = true;
      state.error = null;
    });
    builder.addCase(getPlans.fulfilled, (state, action) => {
      state.allPlans = action.payload.plans;
      state.getPlansLoading = false;
    });
    builder.addCase(getPlans.rejected, (state, action) => {
      state.getPlansLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getClientPlan.pending, (state) => {
      state.getClientPlanLoading = true;
      state.error = null;
    });
    builder.addCase(getClientPlan.fulfilled, (state, action) => {
      state.clientPlan = action.payload.client_plan;
      state.getClientPlanLoading = false;
    });
    builder.addCase(getClientPlan.rejected, (state, action) => {
      state.getClientPlanLoading = false;
      state.error = action.payload;
    });

    builder.addCase(getClientType.pending, (state) => {
      state.getClientTypeLoading = true;
      state.error = null;
    });
    builder.addCase(getClientType.fulfilled, (state, action) => {
      state.clientType = action.payload.client_type;
      state.getClientTypeLoading = false;
    });
    builder.addCase(getClientType.rejected, (state, action) => {
      state.getClientTypeLoading = false;
      state.error = action.payload;
    });
  },
});

export const { systemManagementReset } = systemManagementSlice.actions;

export const systemManagementReducer = systemManagementSlice.reducer;
